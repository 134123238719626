import { TitleProps } from 'src/Routes';
import { DevicePoolStore } from './Stores/DevicePoolStore';
import { Provider } from 'mobx-react';
import { Page } from 'src/Common/Components/Page';
import { DevicePoolManagementContent } from './Components/DevicePoolManagementContent';
import React from 'react';

const devicePoolStore = new DevicePoolStore();

const stores = { devicePoolStore };

export const DevicePoolManagement = ({ title }: TitleProps): JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <DevicePoolManagementContent />
    </Page>
  </Provider>
);
