import {
  CompartmentSelector,
  CompartmentSelectorIndex,
  DevicePool,
  SortingStrategy,
  SortingStrategyType,
  TemplateFilter,
  TemplateFilterType,
} from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export type DevicePoolState = {
  name: string;
  coordinate: string;
  templateFilter: TemplateFilter;
  sortingStrategy: SortingStrategy;
  compartmentSelector?: CompartmentSelector;
};

export class DevicePoolStore {
  @observable
  public editableDevicePool?: DevicePool;

  @observable
  public isSaveable: boolean = false;

  @observable
  public state: DevicePoolState = {
    name: '',
    coordinate: '',
    compartmentSelector: undefined,
    templateFilter: {
      type: TemplateFilterType.NULL,
      templateNames: [],
    },
    sortingStrategy: {
      name: SortingStrategyType.NULL,
      metadata: {},
    },
  };

  @action
  public setState = (newState: Partial<DevicePoolState>): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    this.isSaveable =
      this.state.name !== '' && this.state.coordinate !== '' && this.state.compartmentSelector !== undefined;
  };

  @action setEditableDevicePool = (devicePool: DevicePool): void => {
    this.editableDevicePool = devicePool;
    this.state = devicePool as DevicePoolState;
    this.isSaveable = true;
  };

  @action
  public resetStore = (): void => {
    this.editableDevicePool = undefined;
    this.setState({
      name: '',
      coordinate: '',
      compartmentSelector: undefined,
      sortingStrategy: {
        name: SortingStrategyType.NULL,
        metadata: {},
      },
      templateFilter: {
        type: TemplateFilterType.NULL,
        templateNames: [],
      },
    });
  };

  public getSavableDevicePool(): DevicePool {
    const { name, coordinate, compartmentSelector, templateFilter, sortingStrategy } = this.state;

    return {
      name,
      coordinate,
      templateFilter,
      sortingStrategy,
      compartmentSelector: {
        name: compartmentSelector!.name,
        coordinate: compartmentSelector!.coordinate,
      } as CompartmentSelectorIndex,
    } as DevicePool;
  }
}
