import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fade,
  List,
  ListItem,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { DevicePoolState } from '../Stores/DevicePoolStore';
import { Component } from 'react';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { request } from 'src/Common/Helper/FetchHandler';
import { CompartmentView, EslManagerPublicRouteV1, HttpMethod, Pagination, PaginationResponse } from '@ekkogmbh/apisdk';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import React from 'react';
import { inject } from 'mobx-react';

const styles = FormStyles;

interface DevicePoolSortingStores {
  api: ApiStore;
}

// TODO use proper device pool type
interface DevicePoolSortingProps extends InjectedNotistackProps, WithStyles<typeof styles> {
  devicePool: DevicePoolState;
  onChange?: (sortedItems: CompartmentView[]) => void;
  onError?: () => void;
}

interface DevicePoolSortingState {
  loading: boolean;
  failure: boolean;
  sortedItems: CompartmentView[];
}

@inject('api')
class DevicePoolSortingComponent extends Component<DevicePoolSortingProps, DevicePoolSortingState> {
  public state: DevicePoolSortingState = {
    loading: true,
    failure: false,
    sortedItems: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): DevicePoolSortingStores {
    return this.props as DevicePoolSortingProps & DevicePoolSortingStores;
  }

  public componentDidMount(): void {
    this.fetchItems();
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchItems = async (): Promise<void> => {
    const { api } = this.stores;
    const { enqueueSnackbar, devicePool, onError } = this.props;

    const onErrorCallback = () => {
      this.setState({ failure: true, sortedItems: [] }, onError);
    };

    const pages = await request<PaginationResponse<CompartmentView>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getCompartments({
        page: 1,
        filter: devicePool.compartmentSelector!.coordinate,
        limit: -1,
      } as Pagination),
      EslManagerPublicRouteV1.COMPARTMENTS,
      HttpMethod.GET,
      undefined,
      undefined,
      onErrorCallback,
    );

    this.setState({ loading: false, sortedItems: pages.items ?? [] });
  };

  private onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const { onChange } = this.props;
    const { sortedItems } = this.state;

    if (onChange !== undefined) {
      const updatedItems = arrayMove(sortedItems, oldIndex, newIndex);
      this.setState({ sortedItems: updatedItems }, () => onChange(updatedItems));
    }
  };

  private SortableItem = SortableElement(({ value, sortIndex }: { value: CompartmentView; sortIndex: number }) => (
    <ListItem
      style={{
        borderTopStyle: 'inset',
        borderTopColor: 'rgba(0, 0, 0, 0.23)',
        borderTopWidth: 1,
        backgroundColor: 'white',
        userSelect: 'none',
      }}
    >
      <Typography variant={'subtitle1'} color={'secondary'}>
        <span style={{ display: 'inline-block', minWidth: 40 }}>{sortIndex + 1}.&nbsp;</span>
        <span style={{ display: 'inline-block', minWidth: 160 }}>{value.coordinate}</span>
      </Typography>
    </ListItem>
  ));

  private SortableList = SortableContainer(({ items }: { items: CompartmentView[] }) => (
    <List style={{ padding: 4, width: '100%' }}>
      {items.map((value: CompartmentView, index: number) => (
        <this.SortableItem key={`sortable-item-comp-${index}`} index={index} sortIndex={index} value={value} />
      ))}
    </List>
  ));

  public render() {
    const { loading, failure, sortedItems } = this.state;

    return (
      <div
        style={{
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          borderWidth: 1,
          borderRadius: 4,
          margin: 8,
          padding: 0,
          width: '100%',
        }}
      >
        {loading && (
          <Fade in={true} timeout={2000}>
            <CheckmarkSpinner complete={false} failure={failure} />
          </Fade>
        )}
        {!loading && sortedItems.length < 1 && (
          <Typography color={'secondary'} variant={'subtitle2'}>
            No compartments found.
          </Typography>
        )}
        {!loading && sortedItems.length > 0 && (
          <Accordion elevation={0} defaultExpanded={true}>
            <AccordionSummary>
              <Typography color={'secondary'}>Compartment Order</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <this.SortableList lockAxis={'y'} items={sortedItems} onSortEnd={this.onSortEnd} />
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    );
  }
}

const SnackbarWrapped = withSnackbar<DevicePoolSortingProps>(DevicePoolSortingComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const DevicePoolSorting = StyleWrapped;
