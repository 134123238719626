import { CompartmentSelector, CompartmentSelectorIndex, EslManagerPrivateRoute, HttpMethod } from '@ekkogmbh/apisdk';
import { Fade, ListItemText, MenuItem, SelectProps, WithStyles, withStyles } from '@material-ui/core';
import { inject } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ChangeEvent } from 'react';
import { Component } from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;

interface CompartmentSelectorPickerStores {
  api: ApiStore;
}

interface CompartmentSelectorPickerProps extends InjectedNotistackProps, WithStyles<typeof styles> {
  coordinate: string;
  selected?: CompartmentSelectorIndex;
  optional?: boolean;
  onChange: (selector: CompartmentSelector | undefined) => void;
  onError?: () => void;
  disabled?: boolean;
  renderValue?: SelectProps['renderValue'];
  label?: string;
}

interface CompartmentSelectorPickerState {
  loading: boolean;
  failure: boolean;
  compartmentSelectors: (CompartmentSelector | undefined)[];
}

@inject('api')
class CompartmentSelectorPickerComponent extends Component<
  CompartmentSelectorPickerProps,
  CompartmentSelectorPickerState
> {
  public state: CompartmentSelectorPickerState = {
    loading: true,
    failure: false,
    compartmentSelectors: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): CompartmentSelectorPickerStores {
    return this.props as CompartmentSelectorPickerProps & CompartmentSelectorPickerStores;
  }

  public componentDidMount(): void {
    this.setState({ loading: true }, this.fetchCompartmentSelectors);
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchCompartmentSelectors = async (): Promise<void> => {
    const { api } = this.stores;
    const { coordinate, enqueueSnackbar, onError, onChange, optional, selected } = this.props;

    const onErrorCallback = () => {
      this.setState({ failure: true, compartmentSelectors: [] }, onError);
    };

    let compartmentSelectors: (CompartmentSelector | undefined)[] = [];
    try {
      compartmentSelectors = await request<CompartmentSelector[]>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getAvailableCompartmentSelectors(coordinate),
        EslManagerPrivateRoute.AVAILABLE_COMPARTMENT_SELECTORS,
        HttpMethod.GET,
        undefined,
        undefined,
        onErrorCallback,
      );
    } catch (e) {
      cancelFetchPromises(this.fetchPromises);
    }

    if (optional) {
      compartmentSelectors.unshift(undefined);
    }

    if (compartmentSelectors.length > 0) {
      this.setState({ loading: false, compartmentSelectors });

      if (!selected && !optional) {
        onChange(compartmentSelectors[0]);
      }
    } else {
      onErrorCallback();
    }
  };

  private getListIndex = (selector?: CompartmentSelectorIndex): number => {
    const { compartmentSelectors } = this.state;

    if (selector === undefined) {
      return 0;
    }

    return compartmentSelectors.findIndex(
      (cs: CompartmentSelector | undefined) => cs && cs.name === selector.name && cs.coordinate === selector.coordinate,
    );
  };

  private onChangeSelector = (event: ChangeEvent<{ name?: string; value: unknown }>): void => {
    const { onChange } = this.props;
    const { compartmentSelectors } = this.state;

    const value = event.target.value as number;

    onChange(compartmentSelectors[value]);
  };

  private createMenuItem = (index: number, selector?: CompartmentSelector): JSX.Element => {
    const name = selector ? selector.name : '-';
    const coordinate = selector ? selector.coordinate : '';

    return (
      <MenuItem key={`selector-picker-item-${name}-${coordinate}`} value={index}>
        <ListItemText primary={name} secondary={coordinate} />
      </MenuItem>
    );
  };

  public render() {
    const { label, disabled, selected } = this.props;
    const { compartmentSelectors, loading, failure } = this.state;

    const entries = compartmentSelectors.map((selector: CompartmentSelector | undefined, index: number) =>
      this.createMenuItem(index, selector),
    );

    return (
      <>
        {loading && (
          <div
            style={{
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: 1,
              borderRadius: 4,
              margin: 8,
              padding: 0,
              width: '100%',
            }}
          >
            <Fade in={true} timeout={2000}>
              <CheckmarkSpinner complete={false} failure={failure} />
            </Fade>
          </div>
        )}
        {!loading && (
          <StyledSelectField
            value={selected ? this.getListIndex(selected) : 0}
            label={label ?? 'Compartment Selector'}
            onChange={this.onChangeSelector}
            disabled={disabled}
          >
            {entries}
          </StyledSelectField>
        )}
      </>
    );
  }
}

const SnackbarWrapped = withSnackbar<CompartmentSelectorPickerProps>(CompartmentSelectorPickerComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const CompartmentSelectorPicker = StyleWrapped;
