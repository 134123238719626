import { EslManagerPrivateRoute, HttpMethod, Template } from '@ekkogmbh/apisdk';
import { Checkbox, Fade, ListItemText, MenuItem, SelectProps, WithStyles, withStyles } from '@material-ui/core';
import { inject } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React from 'react';
import { ChangeEvent, Component } from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;

interface TemplatePickerStores {
  api: ApiStore;
}

interface TemplatePickerProps extends InjectedNotistackProps, WithStyles<typeof styles> {
  coordinate: string;
  linkableOnly?: boolean;
  selected?: string | string[];
  optional?: boolean;
  onChange: (selection: string | string[] | undefined) => void;
  onError?: () => void;
  multiple?: boolean;
  disabled?: boolean;
  label?: string;
}

interface TemplatePickerState {
  loading: boolean;
  failure: boolean;
  templateNames: string[];
}

@inject('api')
class TemplatePickerComponent extends Component<TemplatePickerProps, TemplatePickerState> {
  public state: TemplatePickerState = {
    loading: true,
    failure: false,
    templateNames: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): TemplatePickerStores {
    return this.props as TemplatePickerProps & TemplatePickerStores;
  }

  public componentDidMount(): void {
    this.fetchTemplates();
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchTemplates = async (): Promise<void> => {
    const { api } = this.stores;
    const { coordinate, linkableOnly, enqueueSnackbar, onError, onChange, optional, multiple, selected } = this.props;

    const onErrorCallback = () => {
      this.setState({ failure: true, templateNames: [] }, onError);
    };

    let templates: Template[] = [];
    try {
      templates = await request<Template[]>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getAvailableTemplates(coordinate, linkableOnly),
        EslManagerPrivateRoute.AVAILABLE_TEMPLATES,
        HttpMethod.GET,
        undefined,
        undefined,
        onErrorCallback,
      );
    } catch (e) {
      cancelFetchPromises(this.fetchPromises);
    }

    const templateNames = templates
      .reduce((carry: string[], current: Template): string[] => {
        const { name } = current;
        if (!carry.includes(name)) {
          carry.push(name);
        }

        return carry;
      }, [])
      .sort();

    if (templateNames.length > 0) {
      this.setState({ loading: false, templateNames });

      if (!(multiple || optional || selected)) {
        onChange(templateNames[0]);
      }
    } else {
      onErrorCallback();
    }
  };

  private getSelectedIndices = (selection?: string | string[]): number | number[] => {
    const { templateNames } = this.state;

    if (selection instanceof Array) {
      return selection.map((name: string) => templateNames.indexOf(name));
    }

    return selection ? templateNames.indexOf(selection) : -1;
  };

  private onChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { onChange } = this.props;
    const { templateNames } = this.state;

    const selection = event.target.value;

    if (selection instanceof Array) {
      const selectedNames = selection.map((value: number) => templateNames[value]);
      onChange(selectedNames);
    } else {
      const selectedIndex = selection as number;
      onChange(selectedIndex < 0 ? undefined : templateNames[selection as number]);
    }
  };

  public render() {
    const { disabled, multiple, selected, label, optional } = this.props;
    const { templateNames, loading, failure } = this.state;

    const entries: JSX.Element[] = [];

    if (optional && !multiple) {
      entries.push(
        <MenuItem key={'template-option-none'} value={-1}>
          <ListItemText primary={'-'} />
        </MenuItem>,
      );
    }

    templateNames.forEach((name: string, index: number) => {
      entries.push(
        <MenuItem key={`template-option-${index}-${name}`} value={index}>
          {multiple && <Checkbox checked={(selected as string[]).includes(name)} />}
          <ListItemText primary={name} />
        </MenuItem>,
      );
    });

    const renderValue = (value: SelectProps['value']) => {
      const selectedIndices = value as number[];
      const combinedNames = selectedIndices.map((value) => templateNames[value]).join(', ');
      return <ListItemText primary={combinedNames} />;
    };

    return (
      <>
        {loading && (
          <div
            style={{
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: 1,
              borderRadius: 4,
              margin: 8,
              padding: 0,
              width: '100%',
            }}
          >
            <Fade in={true} timeout={2000}>
              <CheckmarkSpinner complete={false} failure={failure} />
            </Fade>
          </div>
        )}
        {!loading && (
          <StyledSelectField
            disabled={disabled}
            multiple={multiple}
            onChange={this.onChange}
            value={this.getSelectedIndices(selected)}
            label={label ?? multiple ? 'Templates' : 'Template'}
            renderValue={multiple ? renderValue : undefined}
          >
            {entries}
          </StyledSelectField>
        )}
      </>
    );
  }
}

const SnackbarWrapped = withSnackbar<TemplatePickerProps>(TemplatePickerComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const TemplatePicker = StyleWrapped;
