import { DevicePool } from '@ekkogmbh/apisdk';
import { MaterialDatatableColumnDef } from 'material-datatable';
import { DatatableColumnDefinitionFn, TableMeta } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  DevicePoolManagementContentActionHandlers,
  DevicePoolManagementContentPropsWithStores,
  DevicePoolManagementContentState,
} from './DevicePoolManagementContent';
import { Delete, Edit } from '@material-ui/icons';
import React from 'react';

class DevicePoolDataTableActions extends DataTableActionsComponent<DevicePool> {}

const Actions = (
  _: DevicePoolManagementContentState,
  __: DevicePoolManagementContentPropsWithStores,
  actions: DevicePoolManagementContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    width: 48 * 2,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (pool: DevicePool, tableMeta: TableMeta): React.ReactNode => {
      const actionButtons: ActionButton<DevicePool>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return (
        <DevicePoolDataTableActions
          dataset={pool}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

const Name = (): MaterialDatatableColumnDef => ({
  name: 'Name',
  field: 'name',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: DevicePool): React.ReactNode => <div style={{ fontWeight: 700 }}>{value.name}</div>,
  },
});

const Coordinate = (): MaterialDatatableColumnDef => ({
  name: 'Coordinate',
  field: 'coordinate',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: DevicePool): React.ReactNode => <div style={{ fontWeight: 700 }}>{value.coordinate}</div>,
  },
});

const CompartmentSelector = (): MaterialDatatableColumnDef => ({
  name: 'Compartment Selector',
  field: 'compartmentSelector',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: DevicePool): React.ReactNode => (
      <div>{value.compartmentSelector.name + '@' + value.compartmentSelector.coordinate}</div>
    ),
  },
});

const TemplateFilter = (): MaterialDatatableColumnDef => ({
  name: 'Template Filter',
  field: 'templateFilter',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: DevicePool): React.ReactNode => (
      <div>{`${value.templateFilter.type} [${value.templateFilter.templateNames.join(', ')}]`}</div>
    ),
  },
});

const SortingStrategy = (): MaterialDatatableColumnDef => ({
  name: 'Sorting Strategy',
  field: 'sortingStrategy',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: DevicePool): React.ReactNode => <div>{`${value.sortingStrategy.name}`}</div>,
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  DevicePoolManagementContentPropsWithStores,
  DevicePoolManagementContentState,
  DevicePoolManagementContentActionHandlers
>> = [Name, Coordinate, CompartmentSelector, TemplateFilter, SortingStrategy, Actions];
