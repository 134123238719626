import {
  CompartmentSelector,
  EslManagerPublicRouteV1,
  HttpMethod,
  Pagination,
  PaginationResponse,
} from '@ekkogmbh/apisdk';
import { Grid, Paper, withStyles, WithStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import { inject } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React from 'react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ConfirmationDialog } from 'src/Common/Components/ConfirmationDialog';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { CompartmentSelectorStore } from '../Stores/CompartmentSelectorStore';
import { CompartmentSelectorManagementStyles } from '../Styles/CompartmentSelectorManagementStyles';
import { materialDatatableColumnDefinitions } from './CompartmentSelectorDatatableColumnDefinitions';
import { CompartmentSelectorPanel, CompartmentSelectorPanelProps } from './CompartmentSelectorPanel';

const styles = CompartmentSelectorManagementStyles;

const stores = ['api', 'compartmentSelectorStore', 'paginationStore', 'searchContentStore', 'navigationStore'];

interface CompartmentSelectorManagementContentActions {
  updateCompartmentSelector?: (compartmentSelector: CompartmentSelector) => void;
}

export interface CompartmentSelectorManagementContentActionHandlers {
  edit: (compartmentSelector: CompartmentSelector) => void;
  delete: (compartmentSelector: CompartmentSelector) => void;
}

export interface CompartmentSelectorManagementContentStores {
  api: ApiStore;
  compartmentSelectorStore: CompartmentSelectorStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

export interface CompartmentSelectorManagementContentState {
  deleteableCompartmentSelector?: CompartmentSelector;
}

export interface CompartmentSelectorManagementContentProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    InjectedNotistackProps {}

export type CompartmentSelectorManagementContentPropsWithStores = CompartmentSelectorManagementContentProps &
  CompartmentSelectorManagementContentStores;

@inject(...stores)
class CompartmentSelectorManagementContentComponent extends Component<
  CompartmentSelectorManagementContentProps,
  CompartmentSelectorManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<CompartmentSelector> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<CompartmentSelector> = { name: 'CS.name' };
  private readonly actions: CompartmentSelectorManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): CompartmentSelectorManagementContentStores {
    return this.props as CompartmentSelectorManagementContentPropsWithStores;
  }

  public state: CompartmentSelectorManagementContentState = {};

  public componentWillUnmount() {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchCompartmentSelectors = async (
    pagination: Pagination,
  ): Promise<PaginationResponse<CompartmentSelector>> => {
    const { api } = this.stores;
    const { enqueueSnackbar } = this.props;

    return await request<PaginationResponse<CompartmentSelector>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getCompartmentSelectors(pagination),
      EslManagerPublicRouteV1.COMPARTMENT_SELECTORS,
      HttpMethod.GET,
    );
  };

  public onDelete = async (compartmentSelector: CompartmentSelector): Promise<void> => {
    this.setState({
      deleteableCompartmentSelector: compartmentSelector,
    });
  };

  public onDeleteDismiss = async () => {
    this.setState({ deleteableCompartmentSelector: undefined });
  };

  public onDeleteConfirm = async () => {
    const { deleteableCompartmentSelector } = this.state;
    const { api, searchContentStore } = this.stores;
    const { enqueueSnackbar } = this.props;

    if (!deleteableCompartmentSelector) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteCompartmentSelector(deleteableCompartmentSelector.coordinate, deleteableCompartmentSelector.name),
      EslManagerPublicRouteV1.COMPARTMENT_SELECTOR,
      HttpMethod.DELETE,
      { 200: 'Compartment Selector deleted.' },
    );

    this.onDeleteDismiss();
    searchContentStore.emitRefresh();
  };

  public onEdit = async (compartmentSelector: CompartmentSelector): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateCompartmentSelector } = this.actions;

    if (updateCompartmentSelector) {
      updateCompartmentSelector(compartmentSelector);
      navigationStore!.scrollTop();
    }
  };

  public onSave = async (compartmentSelector: CompartmentSelector): Promise<CompartmentSelector> => {
    const { api, navigationStore, searchContentStore, compartmentSelectorStore } = this.stores;
    const { editableCompartmentSelector } = compartmentSelectorStore;
    const { enqueueSnackbar } = this.props;

    const doOverwirte = editableCompartmentSelector !== undefined;

    const responeSelector = await request<CompartmentSelector>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveCompartmentSelector(compartmentSelector, doOverwirte),
      EslManagerPublicRouteV1.COMPARTMENT_SELECTOR,
      HttpMethod.PUT,
      { 200: 'Compartment Selector saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return responeSelector;
  };

  public createPanels = (): ContentPanelDefinition[] => {
    const { compartmentSelectorStore } = this.stores;

    const addPanelDefinition: ContentPanelDefinition<CompartmentSelectorPanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: CompartmentSelectorPanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
      },
      permission: Permissions.COMPARTMENT_SELECTORS_WRITE,
      toggleOffCallback: () => compartmentSelectorStore.resetStore(),
      expandHandler: (expandCallback: () => void) => {
        const { updateCompartmentSelector } = this.actions;
        if (updateCompartmentSelector === undefined) {
          this.actions.updateCompartmentSelector = async (compartmentSelector: CompartmentSelector) => {
            expandCallback();
            compartmentSelectorStore.setEditableCompartmentSelector(compartmentSelector);
          };
        }
      },
    };

    return [addPanelDefinition];
  };

  public render() {
    const { deleteableCompartmentSelector } = this.state;
    const columnDefinition: MaterialDatatableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as CompartmentSelectorManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={this.createPanels()} />

        {deleteableCompartmentSelector && (
          <ConfirmationDialog
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={!!deleteableCompartmentSelector}
            title={'Delete Compartment Selector'}
            text={deleteableCompartmentSelector.name}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteConfirm}
          />
        )}

        <Paper>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchCompartmentSelectors}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortColumnIndex: 0,
              sortColumnDirection: 'desc',
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<
  CompartmentSelectorManagementContentProps,
  typeof CompartmentSelectorManagementContentComponent
>(CompartmentSelectorManagementContentComponent);

const SnackbarWrapped = withSnackbar<Omit<CompartmentSelectorManagementContentProps, keyof RouteComponentProps>>(
  RouterWrapped,
);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const CompartmentSelectorManagementContent = StyleWrapped;
