import {
  BlinkColor,
  CompartmentSelector,
  CompartmentSelectorIndex,
  EslManagerPrivateRoute,
  Finder,
  HttpMethod,
  Template,
} from '@ekkogmbh/apisdk';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  SelectProps,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ChangeEvent } from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { BlinkColorPicker } from 'src/Common/Components/Forms/BlinkColorPicker';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { FinderStore } from '../Stores/FinderStore';

const styles = FormStyles;
const checkboxStyle = { margin: 12 };

interface FinderPanelStores {
  api: ApiStore;
  finderStore: FinderStore;
}

interface FinderPanelState {
  loading: boolean;
  findTemplates: boolean;
  selectCompartmentSelector: boolean;
  availableTemplates: string[];
  availableCompartmentSelectors: CompartmentSelector[];
}

export interface FinderPanelProps extends InjectedNotistackProps, WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (finder: Finder) => Promise<Finder>;
}

@inject('api', 'finderStore')
@observer
class FinderPanelComponent extends React.Component<FinderPanelProps, FinderPanelState> {
  public state: FinderPanelState = {
    loading: false,
    findTemplates: false,
    selectCompartmentSelector: false,
    availableTemplates: [],
    availableCompartmentSelectors: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): FinderPanelStores {
    return this.props as FinderPanelProps & FinderPanelStores;
  }

  public componentDidMount(): void {
    const { finderStore } = this.stores;
    const { editableFinder } = finderStore;

    if (editableFinder) {
      if (editableFinder.templateNames && editableFinder.templateNames.length > 0) {
        this.toggleFindTemplates();
      }

      if (editableFinder.compartmentSelector) {
        this.toggleSelectCompartmentSelector();
      }
    }
  }

  public componentWillUnmount(): void {
    const { finderStore } = this.stores;
    finderStore.resetStore();
    cancelFetchPromises(this.fetchPromises);
  }

  public handleReset = async () => {
    const { finderStore } = this.stores;
    const { editableFinder } = finderStore;

    if (editableFinder !== undefined) {
      finderStore.setEditableFinder(editableFinder);
      this.setState({
        findTemplates: editableFinder.templateNames !== undefined && editableFinder.templateNames.length > 0,
        selectCompartmentSelector: editableFinder.compartmentSelector !== undefined,
      });
    } else {
      this.setState(
        {
          findTemplates: false,
          availableTemplates: [],
          selectCompartmentSelector: false,
          availableCompartmentSelectors: [],
        },
        this.componentWillUnmount,
      );
    }
  };

  public handleSave = async () => {
    const { closeHandler, saveHandler } = this.props;
    const { finderStore } = this.stores;

    const payload = finderStore.state as Finder;

    this.setState({ loading: true }, async () => {
      await saveHandler(payload);
      this.handleReset();
      closeHandler();
    });
  };

  public toggleSelectCompartmentSelector = async () => {
    const { enqueueSnackbar } = this.props;
    const { selectCompartmentSelector } = this.state;
    const { api, finderStore } = this.stores;
    const { coordinate, compartmentSelector } = finderStore.state;

    let doToggleOn = !selectCompartmentSelector;
    let availableCompartmentSelectors: CompartmentSelector[] = [];

    if (doToggleOn) {
      try {
        availableCompartmentSelectors = await request<CompartmentSelector[]>(
          api,
          enqueueSnackbar,
          this.fetchPromises,
          api.getAvailableCompartmentSelectors(coordinate),
          EslManagerPrivateRoute.AVAILABLE_COMPARTMENT_SELECTORS,
          HttpMethod.GET,
        );
      } catch (error) {
        doToggleOn = false;
      }
    }

    this.setState({ selectCompartmentSelector: doToggleOn, availableCompartmentSelectors }, () => {
      if (availableCompartmentSelectors.length > 0) {
        finderStore.setState({ compartmentSelector: compartmentSelector ?? availableCompartmentSelectors[0] });
      } else {
        finderStore.setState({ compartmentSelector: undefined });
      }
    });
  };

  public toggleFindTemplates = async () => {
    const { findTemplates } = this.state;

    this.setState({ findTemplates: !findTemplates }, async () => {
      const { api, finderStore } = this.stores;
      const { enqueueSnackbar } = this.props;
      const { coordinate } = finderStore.state;

      if (!findTemplates && coordinate !== '') {
        try {
          const templates = await request<Template[]>(
            api,
            enqueueSnackbar,
            this.fetchPromises,
            api.getAvailableTemplates(coordinate, false),
            EslManagerPrivateRoute.AVAILABLE_TEMPLATES,
            HttpMethod.GET,
          );
          const fetchedNames: string[] = [];
          templates.forEach((template: Template) => {
            if (fetchedNames.indexOf(template.name) === -1) {
              fetchedNames.push(template.name);
            }
          });
          this.setState({ availableTemplates: fetchedNames.sort() });
        } catch (error) {
          this.setState({ findTemplates: false, availableTemplates: [] });
        }
      } else {
        if (finderStore.editableFinder === undefined) {
          this.setState({ availableTemplates: [] });
        }
        finderStore.setState({ templateNames: [] });
      }
    });
  };

  public onTemplateSelect = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { finderStore } = this.stores;
    const { availableTemplates } = this.state;

    const templateIndices = event.target.value as string[];
    const templateNames = templateIndices.map((index: string) => availableTemplates[index]);

    finderStore.setState({ templateNames });
  };

  public onCompartmentSelectorSelect = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { finderStore } = this.stores;
    const { availableCompartmentSelectors } = this.state;
    const value = event.target.value as number;

    const compartmentSelector = availableCompartmentSelectors[value];

    finderStore.setState({ compartmentSelector });
  };

  public getCompartmentSelectorIndex = (selector?: CompartmentSelectorIndex) => {
    const { availableCompartmentSelectors } = this.state;

    if (selector === undefined) {
      return -1;
    }

    return availableCompartmentSelectors.findIndex(
      (cs: CompartmentSelector) => cs.name === selector.name && cs.coordinate === selector.coordinate,
    );
  };

  public render() {
    const { closeHandler } = this.props;
    const {
      loading,
      findTemplates,
      availableTemplates,
      selectCompartmentSelector,
      availableCompartmentSelectors,
    } = this.state;
    const { finderStore } = this.stores;
    const { templateNames, compartmentSelector } = finderStore.state;
    const { editableFinder } = finderStore;

    const templateOptions = availableTemplates.map((templateName: string, index: number) => {
      const templateKey = `template-option-${index}`;
      return (
        <MenuItem key={templateKey} value={index}>
          <Checkbox checked={templateNames!.indexOf(templateName) > -1} />
          <ListItemText primary={templateName} />
        </MenuItem>
      );
    });

    const selectorOptions = availableCompartmentSelectors.map((selector: CompartmentSelector, index: number) => {
      const selectorKey = `${selector.name}@${selector.coordinate}`;
      return (
        <MenuItem key={selectorKey} value={index}>
          <ListItemText primary={selectorKey} />
        </MenuItem>
      );
    });

    const selectedTemplateIndices = templateNames.map((name: string) => availableTemplates.indexOf(name));
    const selectorIndex = compartmentSelector ? this.getCompartmentSelectorIndex(compartmentSelector) : 0;

    const templatesRenderValue = (value: SelectProps['value']) => {
      const selectedIds = value as number[];
      return selectedIds.map((id) => availableTemplates[id]).join(', ');
    };

    return (
      <Grid container spacing={2} alignContent={'stretch'}>
        <Grid item xs={12}>
          <div style={{ display: loading ? 'block' : 'none' }}>
            <Grid container spacing={2} alignItems={'stretch'}>
              <Grid
                item
                xs={12}
                style={{
                  height: 496,
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    top: '50%',
                    marginTop: -48,
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  <CheckmarkSpinner complete={false} failure={false} />
                </div>
              </Grid>
            </Grid>
          </div>

          {!loading && (
            <Grid container item xs={6} spacing={2} alignContent={'stretch'}>
              <Grid item xs={12}>
                <StyledTextField
                  type={'text'}
                  label={'Name'}
                  value={finderStore.state.name}
                  disabled={editableFinder !== undefined}
                  onChange={(e) => finderStore.setState({ name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  type={'text'}
                  label={'Coordinate'}
                  value={finderStore.state.coordinate}
                  disabled={editableFinder !== undefined || findTemplates || selectCompartmentSelector}
                  onChange={(e) => finderStore.setState({ coordinate: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  type={'text'}
                  label={'Compartment Input Instructions'}
                  tooltip={'Instruction text displayed when running the finder.'}
                  value={finderStore.state.compartmentInputInstructions ?? ''}
                  onChange={(e) => finderStore.setState({ compartmentInputInstructions: e.target.value })}
                />
              </Grid>
              <Grid item xs={6}>
                <BlinkColorPicker
                  value={finderStore.state.blinkColor}
                  onChange={(e) => finderStore.setState({ blinkColor: e.target.value as BlinkColor })}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  type={'number'}
                  label={'Blink Duration'}
                  value={finderStore.state.blinkDuration}
                  onChange={(e) => finderStore.setState({ blinkDuration: +e.target.value > 0 ? +e.target.value : 1 })}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  type={'number'}
                  label={'Blink Repeats'}
                  value={finderStore.state.blinkRepeats}
                  onChange={(e) => finderStore.setState({ blinkRepeats: +e.target.value > 0 ? +e.target.value : 1 })}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  type={'number'}
                  label={'Blink Pause'}
                  value={finderStore.state.blinkPause}
                  onChange={(e) => finderStore.setState({ blinkPause: +e.target.value < 0 ? 0 : +e.target.value })}
                />
              </Grid>
            </Grid>
          )}
          {!loading && (
            <Grid item container xs={6} spacing={2} alignContent={'stretch'}>
              <Grid item xs={6}>
                <Tooltip title={'Find labels using the selected templates'} arrow={true}>
                  <FormControlLabel
                    style={checkboxStyle}
                    label="Find Templates"
                    control={
                      <Checkbox
                        disabled={finderStore.state.coordinate === ''}
                        checked={findTemplates}
                        onChange={this.toggleFindTemplates}
                        color="secondary"
                      />
                    }
                  />
                </Tooltip>
              </Grid>
              {findTemplates && availableTemplates.length > 0 && (
                <Grid item xs={6}>
                  <StyledSelectField
                    multiple
                    onChange={this.onTemplateSelect}
                    value={selectedTemplateIndices}
                    label={'Templates'}
                    renderValue={templatesRenderValue}
                  >
                    {templateOptions}
                  </StyledSelectField>
                </Grid>
              )}
            </Grid>
          )}
          {!loading && (
            <Grid item container xs={6} spacing={2} alignContent={'stretch'}>
              <Grid item xs={6}>
                <Tooltip title={'Compartment Selector for narrowing the range of the finder'} arrow={true}>
                  <FormControlLabel
                    style={checkboxStyle}
                    label="Compartment Selector"
                    control={
                      <Checkbox
                        disabled={finderStore.state.coordinate === ''}
                        checked={selectCompartmentSelector}
                        onChange={this.toggleSelectCompartmentSelector}
                        color="secondary"
                      />
                    }
                  />
                </Tooltip>
              </Grid>
              {selectCompartmentSelector && availableCompartmentSelectors.length > 0 && (
                <Grid item xs={6}>
                  <StyledSelectField
                    onChange={this.onCompartmentSelectorSelect}
                    value={selectorIndex}
                    label={'Compartment Selector'}
                  >
                    {selectorOptions}
                  </StyledSelectField>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormPanelButtons
            cancelHandler={closeHandler}
            saveHandler={this.handleSave}
            resetHandler={this.handleReset}
            isDeleteHidden={true}
            isSaveDisabled={!finderStore.state.allFilled}
          />
        </Grid>
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<FinderPanelProps>(FinderPanelComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const FinderPanel = StyleWrapped;
