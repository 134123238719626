import { BlinkColor } from '@ekkogmbh/apisdk';
import { MenuItem, withStyles, WithStyles } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import React from 'react';
import { Component } from 'react';
import { FormStyles } from '../../Styles/FormStyles';
import { BlinkColorItem } from '../BlinkColorItem';
import { StyledSelectField } from './StyledSelectField';

const styles = FormStyles;

interface BlinkColorPickerProps extends WithStyles<typeof styles> {
  value: SelectProps['value'];
  onChange: SelectInputProps['onChange'];
  multiple?: boolean;
  disabled?: boolean;
  renderValue?: SelectProps['renderValue'];
  label?: string;
}

class BlinkColorPickerComponent extends Component<BlinkColorPickerProps> {
  public render() {
    const { value, disabled, multiple, onChange, label } = this.props;

    const colorOptions = Object.entries(BlinkColor).map(([key, colorName]) => (
      <MenuItem key={`blink-color-option-${key}`} value={colorName}>
        <BlinkColorItem color={colorName} />
      </MenuItem>
    ));

    return (
      <StyledSelectField
        label={label ?? 'Blink Color'}
        multiple={multiple}
        disabled={disabled}
        onChange={onChange}
        value={value}
      >
        {colorOptions}
      </StyledSelectField>
    );
  }
}

const StyleWrapped = withStyles(styles)(BlinkColorPickerComponent);

export const BlinkColorPicker = StyleWrapped;
