import { CompartmentSelector } from '@ekkogmbh/apisdk';
import { Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import React from 'react';
import { DatatableColumnDefinitionFn, TableMeta } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  CompartmentSelectorManagementContentActionHandlers,
  CompartmentSelectorManagementContentPropsWithStores,
  CompartmentSelectorManagementContentState,
} from './CompartmentSelectorManagementContent';

class CompartmentSelectorDataTableActions extends DataTableActionsComponent<CompartmentSelector> {}

const Actions = (
  _: CompartmentSelectorManagementContentState,
  __: CompartmentSelectorManagementContentPropsWithStores,
  actions: CompartmentSelectorManagementContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    width: 48 * 2,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (compartmentSelector: CompartmentSelector, tableMeta: TableMeta): React.ReactNode => {
      const actionButtons: ActionButton<CompartmentSelector>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return (
        <CompartmentSelectorDataTableActions
          dataset={compartmentSelector}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

const Name = (): MaterialDatatableColumnDef => ({
  name: 'Name',
  field: 'name',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: CompartmentSelector): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{value.name}</div>
    ),
  },
});

const Coordinate = (): MaterialDatatableColumnDef => ({
  name: 'Coordinate',
  field: 'coordinate',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: CompartmentSelector): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{value.coordinate}</div>
    ),
  },
});

const Type = (): MaterialDatatableColumnDef => ({
  name: 'Type',
  field: 'type',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
  },
});

const Configuration = (): MaterialDatatableColumnDef => ({
  name: 'Configuration',
  field: 'configuration',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: CompartmentSelector): React.ReactNode => (
      <div>
        {Object.keys(value.configuration).map((key: string) => (
          <div
            key={key}
            style={{
              display: 'inline-block',
              width: 155,
              whiteSpace: 'normal',
              wordBreak: 'break-all',
            }}
          >
            <div style={{ margin: 8 }}>
              <Typography
                variant={'subtitle2'}
                color={'textSecondary'}
                style={{
                  fontWeight: 700,
                  wordBreak: 'keep-all',
                }}
              >
                {key}
              </Typography>
            </div>
            <div>
              <span>{value.configuration[key]}</span>
            </div>
          </div>
        ))}
      </div>
    ),
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  CompartmentSelectorManagementContentPropsWithStores,
  CompartmentSelectorManagementContentState,
  CompartmentSelectorManagementContentActionHandlers
>> = [Name, Coordinate, Type, Configuration, Actions];
