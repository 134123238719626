import { PickingArea } from '@ekkogmbh/apisdk';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import { AssignmentLate, AssignmentReturn, AssignmentTurnedIn, Check, Clear, Delete, Edit } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import React from 'react';
import { BlinkColorItem } from 'src/Common/Components/BlinkColorItem';
import { DatatableColumnDefinitionFn, TableMeta } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  PickingAreasContentActionHandlers,
  PickingAreasContentPropsWithStores,
  PickingAreasContentState,
} from './PickingAreasContent';

class PickingAreaDataTableActions extends DataTableActionsComponent<PickingArea> {}

const textStyle: CSSProperties = {
  verticalAlign: 'middle',
};

const iconStyle: CSSProperties = {
  height: 16,
  width: 16,
  marginLeft: 8,
  color: 'rgba(153, 153, 153, 1)',
  ...textStyle,
};

const AreaExternalIdentifier = (): MaterialDatatableColumnDef => ({
  name: 'External-Identifier',
  field: 'id',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: PickingArea): React.ReactNode => <div style={{ fontWeight: 700 }}>{value.id}</div>,
  },
});

const AreaPickingType = (): MaterialDatatableColumnDef => ({
  name: 'Picking-Type',
  field: 'type',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: PickingArea): React.ReactNode => <div style={{ fontWeight: 700 }}>{value.type}</div>,
  },
});

const AreaRequiredSide = (): MaterialDatatableColumnDef => ({
  name: 'Required-Side',
  field: 'requiredSide',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: PickingArea): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>
        <span style={{ ...textStyle } as React.CSSProperties}>{value.requiredSide.name}</span>
        <span>
          <Tooltip title={value.requiredSideAck ? 'ACK' : 'NO-ACK'} placement="top" enterDelay={500} leaveDelay={200}>
            {value.requiredSideAck ? (
              <AssignmentTurnedIn style={{ ...iconStyle } as React.CSSProperties} />
            ) : (
              <AssignmentLate style={{ ...iconStyle } as React.CSSProperties} />
            )}
          </Tooltip>
        </span>
      </div>
    ),
  },
});

const AreaOptionalSide = (): MaterialDatatableColumnDef => ({
  name: 'Optional-Side',
  field: 'optionalSide',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: PickingArea): React.ReactNode => (
      <div>
        {value.optionalSide !== undefined && value.optionalSide !== null && (
          <React.Fragment>
            <span style={{ ...textStyle } as React.CSSProperties}>{value.optionalSide.name}</span>
            <span>
              <Tooltip
                title={value.optionalSideAck ? (value.symmetricalAck ? 'SYMMETRIC-ACK' : 'ACK') : 'NO-ACK'}
                placement="top"
                enterDelay={500}
                leaveDelay={200}
              >
                {value.optionalSideAck ? (
                  value.symmetricalAck ? (
                    <AssignmentReturn style={{ ...iconStyle } as React.CSSProperties} />
                  ) : (
                    <AssignmentTurnedIn style={{ ...iconStyle } as React.CSSProperties} />
                  )
                ) : (
                  <AssignmentLate style={{ ...iconStyle } as React.CSSProperties} />
                )}
              </Tooltip>
            </span>
          </React.Fragment>
        )}
        {!value.optionalSide && '-'}
      </div>
    ),
  },
});

// // @ts-ignore
// const AreaRequiredSideAck = (): MaterialDatatableColumnDef => ({
//   name: 'Required-Side ACK',
//   field: 'requiredSideAck',
//   options: {
//     sort: false,
//     filter: false,
//     headerNoWrap: true,
//     customBodyRender: (value: PickingArea): React.ReactNode => value.requiredSideAck ? 'yes' : 'no',
//   },
// });
//
// // @ts-ignore
// const AreaOptionalSideAck = (): MaterialDatatableColumnDef => ({
//   name: 'Optional-Side ACK',
//   field: 'optionalSideAck',
//   options: {
//     sort: false,
//     filter: false,
//     headerNoWrap: true,
//     customBodyRender: (value: PickingArea): React.ReactNode => value.optionalSideAck ? 'yes' : 'no',
//   },
// });

const AreaColors = (): MaterialDatatableColumnDef => ({
  name: 'Blink-Colors',
  field: 'colors',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: PickingArea): React.ReactNode =>
      value.colors.map((color: string, index: number) => <BlinkColorItem key={index} color={color} />),
  },
});

const AreaNextColor = (): MaterialDatatableColumnDef => ({
  name: 'Next-Color',
  field: 'nextColor',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: ({ nextColor }: PickingArea): React.ReactNode => <BlinkColorItem color={nextColor} />,
  },
});

const AreaSimultaneousBlinkCount = (): MaterialDatatableColumnDef => ({
  name: 'Blink-Count',
  field: 'simultaneousBlinkCount',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: PickingArea): React.ReactNode => value.simultaneousBlinkCount,
  },
});

const AreaDefaultBlinkDuration = (): MaterialDatatableColumnDef => ({
  name: 'Blink-Duration',
  field: 'defaultBlinkDuration',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: PickingArea): React.ReactNode => value.defaultBlinkDuration,
  },
});

const AreaMaximumSimultaneouslyRunningJobs = (): MaterialDatatableColumnDef => ({
  name: 'Concurrent-Jobs',
  field: 'maximumSimultaneouslyRunningJobs',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: PickingArea): React.ReactNode => value.maximumSimultaneouslyRunningJobs,
  },
});

const AreaAckByButtonTopic = (): MaterialDatatableColumnDef => ({
  name: 'Acknowledge-With-Button',
  field: 'ackByButtonTopic',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: PickingArea): React.ReactNode => {
      if (!value.ackByButtonTopic) {
        return (
          <div>
            <Clear />
          </div>
        );
      }

      return (
        <div>
          <Tooltip
            title={
              <span>
                topic: <b>{value.ackByButtonTopic}</b>
              </span>
            }
            placement="top"
            enterDelay={500}
            leaveDelay={200}
          >
            <Check />
          </Tooltip>
        </div>
      );
    },
  },
});

const AutomaticJobFinish = (): MaterialDatatableColumnDef => ({
  name: 'Automatic-Job-Finish',
  field: 'automaticJobFinish',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: PickingArea): React.ReactNode => (value.automaticJobFinish ? <Check /> : <Clear />),
  },
});

const AreaActions = (
  _: PickingAreasContentState,
  propsWithStores: PickingAreasContentPropsWithStores,
  actions: PickingAreasContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    width: 48 * 3,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (pickingArea: PickingArea, tableMeta: TableMeta): React.ReactNode => {
      const hasWritePermission = propsWithStores.api.userHasPermissionOnAnyNode(Permissions.PICKING_AREAS_WRITE);

      const actionButtons: ActionButton<PickingArea>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
          disabled: !hasWritePermission,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return (
        <PickingAreaDataTableActions
          dataset={pickingArea}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  PickingAreasContentPropsWithStores,
  PickingAreasContentState,
  PickingAreasContentActionHandlers
>> = [
  AreaExternalIdentifier,
  // AreaRequiredSideAck,
  AreaPickingType,
  AreaRequiredSide,
  AreaOptionalSide,
  // AreaOptionalSideAck,
  AreaColors,
  AreaNextColor,
  AreaSimultaneousBlinkCount,
  AreaDefaultBlinkDuration,
  AreaMaximumSimultaneouslyRunningJobs,
  AutomaticJobFinish,
  AreaAckByButtonTopic,
  AreaActions,
];
