import { Grid, Paper, WithStyles, withStyles } from '@material-ui/core';
import { DevicePoolManagementStyles } from '../Styles/DevicePoolManagementStyles';
import { RouteComponentProps, withRouter } from 'react-router';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import { Component } from 'react';
import { inject } from 'mobx-react';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import React from 'react';
import { Add } from '@material-ui/icons';
import { DevicePoolPanel, DevicePoolPanelProps } from './DevicePoolPanel';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import { DevicePool, EslManagerPublicRouteV2, HttpMethod, Pagination, PaginationResponse } from '@ekkogmbh/apisdk';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { MaterialDatatableColumnDef } from 'material-datatable';
import { materialDatatableColumnDefinitions } from './DevicePoolColumnDefinitions';
import { request } from 'src/Common/Helper/FetchHandler';
import { DevicePoolStore } from '../Stores/DevicePoolStore';
import { ConfirmationDialog } from 'src/Common/Components/ConfirmationDialog';

const styles = DevicePoolManagementStyles;

const stores = ['api', 'searchContentStore', 'navigationStore', 'devicePoolStore'];

interface DevicePoolManagementContentActions {
  updateDevicePool?: (devicePool: DevicePool) => void;
}

export interface DevicePoolManagementContentActionHandlers {
  edit: (devicePool: DevicePool) => void;
  delete: (devicePool: DevicePool) => void;
}

export interface DevicePoolManagementContentStores {
  api: ApiStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  devicePoolStore: DevicePoolStore;
}

export interface DevicePoolManagementContentState {
  deletableDevicePool?: DevicePool;
}

export interface DevicePoolManagementContentProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    InjectedNotistackProps {}

export type DevicePoolManagementContentPropsWithStores = DevicePoolManagementContentProps &
  DevicePoolManagementContentStores;

@inject(...stores)
class DevicePoolManagementContentComponent extends Component<
  DevicePoolManagementContentProps,
  DevicePoolManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<DevicePool> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<DevicePool> = { name: 'DP.name' };
  private readonly actions: DevicePoolManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): DevicePoolManagementContentStores {
    return this.props as DevicePoolManagementContentPropsWithStores;
  }

  public state: DevicePoolManagementContentState = {};

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchDevicePools = async (pagination: Pagination): Promise<PaginationResponse<DevicePool>> => {
    const { api } = this.stores;
    const { enqueueSnackbar } = this.props;

    return await request<PaginationResponse<DevicePool>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getDevicePools(pagination),
      EslManagerPublicRouteV2.DEVICE_POOLS,
      HttpMethod.GET,
    );
  };

  private onSave = async (): Promise<void> => {
    const { api, navigationStore, searchContentStore, devicePoolStore } = this.stores;
    const { enqueueSnackbar } = this.props;

    const allowOverwrite = devicePoolStore.editableDevicePool !== undefined;

    await request<DevicePool>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveDevicePool(devicePoolStore.getSavableDevicePool(), allowOverwrite),
      EslManagerPublicRouteV2.DEVICE_POOL,
      HttpMethod.PUT,
      { 200: 'Device Pool saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();
  };

  private onEdit = async (devicePool: DevicePool): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateDevicePool } = this.actions;

    if (updateDevicePool) {
      updateDevicePool(devicePool);
      navigationStore!.scrollTop();
    }
  };

  private onDeleteConfirm = async () => {
    const { deletableDevicePool } = this.state;
    const { api, searchContentStore } = this.stores;
    const { enqueueSnackbar } = this.props;

    if (!deletableDevicePool) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteDevicePool(deletableDevicePool),
      EslManagerPublicRouteV2.DEVICE_POOL,
      HttpMethod.DELETE,
      { 200: 'Device Pool deleted.' },
    );

    this.setState({ deletableDevicePool: undefined }, () => searchContentStore.emitRefresh());
  };

  private createPanel = (): ContentPanelDefinition<DevicePoolPanelProps> => {
    const { devicePoolStore } = this.stores;
    return {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: DevicePoolPanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
      },
      permission: Permissions.DEVICE_POOLS_WRITE,
      toggleOffCallback: devicePoolStore.resetStore,
      expandHandler: (expandCallback: () => void) => {
        const { updateDevicePool } = this.actions;
        if (updateDevicePool === undefined) {
          this.actions.updateDevicePool = async (devicePool: DevicePool) => {
            expandCallback();
            devicePoolStore.setEditableDevicePool(devicePool);
          };
        }
      },
    };
  };

  public render() {
    const { deletableDevicePool } = this.state;

    const columnDefinition: MaterialDatatableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as DevicePoolManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: (devicePool) => this.setState({ deletableDevicePool: devicePool }),
      }),
    );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={[this.createPanel()]} />

        {deletableDevicePool && (
          <ConfirmationDialog
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={!!deletableDevicePool}
            title={'Delete Device Pool'}
            text={deletableDevicePool.name}
            onClose={() => this.setState({ deletableDevicePool: undefined })}
            onConfirm={this.onDeleteConfirm}
          />
        )}

        <Paper>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchDevicePools}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortColumnIndex: 0,
              sortColumnDirection: 'desc',
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<DevicePoolManagementContentProps, typeof DevicePoolManagementContentComponent>(
  DevicePoolManagementContentComponent,
);
const SnackbarWrapped = withSnackbar<Omit<DevicePoolManagementContentProps, keyof RouteComponentProps>>(RouterWrapped);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const DevicePoolManagementContent = StyleWrapped;
