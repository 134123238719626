import { TemplateType } from '@ekkogmbh/apisdk';
import {
  Fade,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ChangeEvent } from 'react';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { enumKeys } from 'src/Common/Helper/Enum';
import { ConfigStore } from 'src/Common/Stores/ConfigStore';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { TemplateStore } from '../Stores/TemplateStore';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api', 'templateStore', 'configStore'];

interface TemplateFormStores {
  templateStore: TemplateStore;
  configStore: ConfigStore;
}

interface TemplateFormState {
  loading: boolean;
}

interface TemplateFormProps extends WithStyles<typeof styles>, InjectedNotistackProps {}

@inject(...stores)
@observer
class TemplateFormComponent extends React.Component<TemplateFormProps, TemplateFormState> {
  public state: TemplateFormState = {
    loading: false,
  };

  get stores(): TemplateFormStores {
    return this.props as TemplateFormProps & TemplateFormStores;
  }

  public handleChange = (fieldName: string) => async ({
    target: { value },
  }: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { templateStore } = this.stores;

    switch (fieldName) {
      case 'name':
        templateStore.setState({ name: (value as string).trim() }, true);
        break;
      case 'type':
        templateStore.setState({ type: value as TemplateType }, true);
        break;
      case 'coordinate':
        templateStore.setState({ coordinate: (value as string).trim() }, true);
    }

    templateStore.changed = true;
  };

  public render() {
    const { loading } = this.state;
    const { classes } = this.props;
    const { templateStore } = this.stores;
    const { name, coordinate } = templateStore.state;
    const { editableTemplate } = templateStore;

    const templateType = templateStore.state.type;

    const inputLabelProps = {
      classes: {
        root: classes.label,
        focused: classes.focused,
      },
    };

    const inputProps = {
      classes: {
        root: classes.outlinedInput,
        focused: classes.focused,
        notchedOutline: classes.notchedOutline,
        disabled: classes.disabled,
      },
    };

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {loading && <LoadingMask />}

        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <Grid container spacing={2} alignItems={'stretch'}>
              <Grid item xs={12}>
                <TextField
                  label={'Name'}
                  value={name}
                  name={'name'}
                  onChange={this.handleChange('name')}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                  disabled={!!editableTemplate}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label={'Coordinate'}
                  value={coordinate}
                  name={'coordinate'}
                  onChange={this.handleChange('coordinate')}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                  disabled={!!editableTemplate}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.margin}>
                  <InputLabel htmlFor="outlined-Type-native-simple">Type</InputLabel>
                  <Select
                    native
                    disabled
                    value={templateType}
                    onChange={this.handleChange('type')}
                    input={<OutlinedInput name="Type" labelWidth={34} id="outlined-Type-native-simple" />}
                  >
                    {enumKeys(TemplateType).map((possibleTemplateType) => (
                      <option key={possibleTemplateType} value={TemplateType[possibleTemplateType]}>
                        {possibleTemplateType}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Fade>
        </Grid>
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<TemplateFormProps>(TemplateFormComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const TemplateForm = StyleWrapped;
