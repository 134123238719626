import { Finder } from '@ekkogmbh/apisdk';
import { Delete, Edit } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import React from 'react';
import { BlinkColorItem } from 'src/Common/Components/BlinkColorItem';
import { DatatableColumnDefinitionFn, TableMeta } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  FinderManagementContentActionHandlers,
  FinderManagementContentPropsWithStores,
  FinderManagementContentState,
} from './FinderManagementContet';

class FinderDataTableActions extends DataTableActionsComponent<Finder> {}

const Actions = (
  _: FinderManagementContentState,
  __: FinderManagementContentPropsWithStores,
  actions: FinderManagementContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    width: 48 * 2,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (finder: Finder, tableMeta: TableMeta): React.ReactNode => {
      const actionButtons: ActionButton<Finder>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return (
        <FinderDataTableActions
          dataset={finder}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

const Name = (): MaterialDatatableColumnDef => ({
  name: 'Name',
  field: 'name',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: Finder): React.ReactNode => <div style={{ fontWeight: 700 }}>{value.name}</div>,
  },
});

const Coordinate = (): MaterialDatatableColumnDef => ({
  name: 'Coordinate',
  field: 'coordinate',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: Finder): React.ReactNode => <div style={{ fontWeight: 700 }}>{value.coordinate}</div>,
  },
});

const Color = (): MaterialDatatableColumnDef => ({
  name: 'Color',
  field: 'blinkColor',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: Finder): React.ReactNode => <BlinkColorItem color={value.blinkColor} />,
  },
});

const BlinkDuration = (): MaterialDatatableColumnDef => ({
  name: 'Blink Duration',
  field: 'blinkDuration',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
  },
});

const BlinkRepeats = (): MaterialDatatableColumnDef => ({
  name: 'Blink Repeats',
  field: 'blinkRepeats',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
  },
});

const BlinkPause = (): MaterialDatatableColumnDef => ({
  name: 'Blink Pause',
  field: 'blinkPause',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
  },
});

const TemplateNames = (): MaterialDatatableColumnDef => ({
  name: 'Template Names',
  field: 'templateNames',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (finder: Finder): React.ReactNode => (
      <div>{finder.templateNames && finder.templateNames.length > 0 ? finder.templateNames.join(', ') : '-'}</div>
    ),
  },
});

const CompartmentSelector = (): MaterialDatatableColumnDef => ({
  name: 'Compartment Selector',
  field: 'compartmentSelector',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (finder: Finder): React.ReactNode => (
      <div>
        {finder.compartmentSelector
          ? finder.compartmentSelector.name + '@' + finder.compartmentSelector.coordinate
          : '-'}
      </div>
    ),
  },
});

const CompartmentInputColumn = (): MaterialDatatableColumnDef => ({
  name: 'Compartment Input Instructions',
  field: 'compartmentInputInstructions',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (finder: Finder): React.ReactNode => <div>{finder.compartmentInputInstructions ?? '-'}</div>,
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  FinderManagementContentPropsWithStores,
  FinderManagementContentState,
  FinderManagementContentActionHandlers
>> = [
  Name,
  Coordinate,
  Color,
  BlinkDuration,
  BlinkRepeats,
  BlinkPause,
  TemplateNames,
  CompartmentSelector,
  CompartmentInputColumn,
  Actions,
];
