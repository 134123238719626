import { BlinkColor, CompartmentSelectorIndex, Finder } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export interface FinderState {
  name: string;
  coordinate: string;
  blinkColor: BlinkColor;
  blinkDuration: number;
  blinkRepeats: number;
  blinkPause: number;
  templateNames: string[];
  compartmentSelector?: CompartmentSelectorIndex;
  compartmentInputInstructions?: string | null;
  allFilled: boolean;
}

export class FinderStore {
  @observable
  public editableFinder?: Finder;

  @observable
  public state: FinderState = {
    name: '',
    coordinate: '',
    blinkColor: BlinkColor.RED,
    blinkDuration: 30,
    blinkRepeats: 1,
    blinkPause: 0,
    templateNames: [],
    compartmentSelector: undefined,
    allFilled: false,
  };

  @action
  public setState = (newState: Partial<FinderState>, setAllFilled: boolean = true): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    if (setAllFilled) {
      const allFilled = this.state.name !== '' && this.state.coordinate !== '';
      this.state = {
        ...this.state,
        allFilled,
      };
    }
  };

  @action
  public resetStore = (): void => {
    this.setState(
      {
        name: '',
        coordinate: '',
        blinkColor: BlinkColor.RED,
        blinkDuration: 30,
        blinkRepeats: 1,
        blinkPause: 0,
        templateNames: [],
        compartmentSelector: undefined,
        compartmentInputInstructions: undefined,
        allFilled: false,
      },
      false,
    );

    this.editableFinder = undefined;
  };

  @action setEditableFinder = (finder: Finder): void => {
    this.editableFinder = finder;

    if (finder) {
      this.setState(finder as FinderState, true);
    }
  };
}
