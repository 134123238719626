import { BlinkColor } from '@ekkogmbh/apisdk';
import { withStyles, WithStyles } from '@material-ui/core';
import { blue, cyan, green, grey, pink, red, yellow } from '@material-ui/core/colors';
import { Stop } from '@material-ui/icons';
import React from 'react';
import { Component } from 'react';
import { FormStyles } from '../Styles/FormStyles';

const styles = FormStyles;

interface BlinkColorItemProps extends WithStyles<typeof styles> {
  color: BlinkColor | string;
  saturation?: number;
}

/**
 * Maps the color choice to Material-UI color format if possible and applies given saturation values.
 */
class BlinkColorItemComponent extends Component<BlinkColorItemProps> {
  public getColorCSS(): string {
    const { color } = this.props;
    const saturation = this.props.saturation ?? 400;

    switch (color) {
      case BlinkColor.GREEN:
        return green[saturation];
      case BlinkColor.RED:
        return red[saturation];
      case BlinkColor.BLUE:
        return blue[saturation];
      case BlinkColor.CYAN:
        return cyan[saturation];
      case BlinkColor.MAGENTA:
        // close enough
        return pink[saturation];
      case BlinkColor.WHITE:
        // grey is visible on a white background
        return grey[saturation];
      case BlinkColor.YELLOW:
        return yellow[saturation];
      default:
        // colors not covered in the mapping may still work simply by using the name
        return (color as string).toLowerCase();
    }
  }

  /**
   * Ensures proper word form (BLUE -> Blue).
   */
  public getColorName(): string {
    const { color } = this.props;

    return color[0].toUpperCase() + color.slice(1).toLowerCase();
  }

  public render() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Stop
          style={
            {
              height: 20,
              width: 20,
              marginLeft: 0,
              color: this.getColorCSS(),
              verticalAlign: 'middle',
            } as React.CSSProperties
          }
        />
        {this.getColorName()}
      </div>
    );
  }
}

const StyleWrapped = withStyles(styles)(BlinkColorItemComponent);

export const BlinkColorItem = StyleWrapped;
