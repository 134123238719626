import { FieldReplacement } from '@ekkogmbh/apisdk';
import { Button, Divider, Grid, Tab, Tabs, withStyles, WithStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React from 'react';
import { Component } from 'react';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { CompartmentGeneratorStore } from '../Stores/CompartmentGeneratorStore';

const styles = FormStyles;

interface CompartmentGeneratorFormState {
  activeTab: number;
  fieldInput: string;
}

interface CompartmentGeneratorFormProps extends WithStyles<typeof styles>, InjectedNotistackProps {}

interface CompartmentGeneratorFormStores {
  compartmentGeneratorStore: CompartmentGeneratorStore;
}

type CompartmentGeneratorFormPropsWithStores = CompartmentGeneratorFormProps & CompartmentGeneratorFormStores;

@inject('compartmentGeneratorStore')
@observer
class CompartmentGeneratorFormComponent extends Component<
  CompartmentGeneratorFormProps,
  CompartmentGeneratorFormState
> {
  public state: CompartmentGeneratorFormState = {
    activeTab: 0,
    fieldInput: '',
  };

  get stores(): CompartmentGeneratorFormStores {
    return this.props as CompartmentGeneratorFormPropsWithStores;
  }

  public onAddField(): void {
    const { fieldInput } = this.state;
    const { compartmentGeneratorStore } = this.stores;

    if (fieldInput !== '') {
      compartmentGeneratorStore.addField(fieldInput);
      this.setState({ fieldInput: '', activeTab: compartmentGeneratorStore.fields.length });
    }
  }

  public onRemoveField(): void {
    const { activeTab } = this.state;
    const { compartmentGeneratorStore } = this.stores;

    const fieldIndex = activeTab - 1;

    this.setState({ activeTab: 0 }, () => compartmentGeneratorStore.removeField(fieldIndex));
  }

  public renderField(): JSX.Element {
    const { activeTab } = this.state;
    const { compartmentGeneratorStore } = this.stores;

    const fieldIndex = activeTab > 0 ? activeTab - 1 : undefined;

    const { pattern, replacement } =
      fieldIndex !== undefined ? compartmentGeneratorStore.fields[fieldIndex] : compartmentGeneratorStore.coordinate;

    return (
      <Grid container spacing={1} alignItems={'stretch'} alignContent={'center'} style={{ paddingRight: 20 }}>
        <Grid item xs={12}>
          <StyledTextField
            type={'text'}
            label={'Pattern'}
            value={pattern}
            onChange={(e) => compartmentGeneratorStore.setPattern(e.target.value, fieldIndex)}
            tooltip={'Regular expression pattern for extracting values'}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            type={'text'}
            label={'Replacement'}
            value={replacement}
            onChange={(e) => compartmentGeneratorStore.setReplacement(e.target.value, fieldIndex)}
            tooltip={'Value generation template'}
          />
        </Grid>
      </Grid>
    );
  }

  public render(): JSX.Element {
    const { activeTab, fieldInput } = this.state;
    const { compartmentGeneratorStore } = this.stores;

    const isAddDisabled = fieldInput === '' || compartmentGeneratorStore.getFieldByName(fieldInput) !== undefined;

    return (
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item xs={12}>
          <Tabs
            value={activeTab}
            variant={'scrollable'}
            scrollButtons={'auto'}
            onChange={(_, newTab) => this.setState({ activeTab: newTab })}
          >
            <Tab key={`tGroup-coord`} label={'coordinate'} />
            {compartmentGeneratorStore.fields.map((field: FieldReplacement, index: number) => (
              <Tab key={`tGroup-${index}`} label={field ? field.key : ''} />
            ))}
          </Tabs>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {this.renderField()}
          <Divider />
        </Grid>
        <Grid container item xs={6} spacing={1} alignItems={'center'} alignContent={'stretch'}>
          <Grid container item xs={9} spacing={2} alignItems={'center'} alignContent={'stretch'}>
            <Grid item xs={9}>
              <StyledTextField
                type={'text'}
                label={'Field'}
                value={fieldInput}
                onChange={(e) => this.setState({ fieldInput: e.target.value })}
              />
            </Grid>
            <Grid item xs={3}>
              <Button variant={'outlined'} disabled={isAddDisabled} onClick={() => this.onAddField()}>
                add
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant={'contained'}
              color={'secondary'}
              disabled={activeTab === 0}
              onClick={() => this.onRemoveField()}
            >
              remove
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<CompartmentGeneratorFormProps>(CompartmentGeneratorFormComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const CompartmentGeneratorForm = StyleWrapped;
