import { FormStyles } from 'src/Common/Styles/FormStyles';
import { DevicePoolStore } from '../Stores/DevicePoolStore';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import { Divider, Grid, ListItemText, MenuItem, Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { CompartmentSelectorIndex, CompartmentView, SortingStrategyType, TemplateFilterType } from '@ekkogmbh/apisdk';
import { inject, observer } from 'mobx-react';
import { DynamicStepper, DynamicStepperStep } from 'src/Common/Components/Stepper/DynamicStepper';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { CompartmentSelectorPicker } from 'src/CompartmentSelectorManagement/CompartmentSelectorPicker';
import { TemplatePicker } from 'src/TemplateManagement/TemplatePicker';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { DevicePoolSorting } from './DevicePoolSorting';

const styles = FormStyles;

interface DevicePoolPanelStores {
  api: ApiStore;
  devicePoolStore: DevicePoolStore;
}

interface DevicePoolPanelState {
  loading: boolean;
  activeStep: number;
}

export interface DevicePoolPanelProps extends InjectedNotistackProps, WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: () => Promise<void>;
}

@inject('api', 'devicePoolStore')
@observer
class DevicePoolPanelComponent extends React.Component<DevicePoolPanelProps, DevicePoolPanelState> {
  public state: DevicePoolPanelState = {
    loading: false,
    activeStep: 0,
  };

  get stores(): DevicePoolPanelStores {
    return this.props as DevicePoolPanelProps & DevicePoolPanelStores;
  }

  public componentWillUnmount(): void {
    const { devicePoolStore } = this.stores;
    devicePoolStore.resetStore();
  }

  private namingStep = (): DynamicStepperStep => {
    const { devicePoolStore } = this.stores;
    const { name, coordinate } = devicePoolStore.state;

    return {
      title: 'Device Pool',
      elementCallback: () => (
        <Grid container spacing={2} alignContent={'stretch'}>
          <Grid item xs={8}>
            <StyledTextField
              type={'text'}
              label={'Name'}
              value={name}
              onChange={(e) => devicePoolStore.setState({ name: e.target.value })}
            />
          </Grid>
          <Grid item xs={8}>
            <StyledTextField
              type={'text'}
              label={'Coordinate'}
              value={coordinate}
              onChange={(e) => devicePoolStore.setState({ coordinate: e.target.value })}
            />
          </Grid>
        </Grid>
      ),
    };
  };

  private selectorStep = (): DynamicStepperStep => {
    const { activeStep } = this.state;
    const { devicePoolStore } = this.stores;
    const { coordinate, compartmentSelector } = devicePoolStore.state;

    return {
      title: 'Compartment Selector',
      elementCallback: () => (
        <Grid item xs={8}>
          <CompartmentSelectorPicker
            coordinate={coordinate}
            selected={compartmentSelector as CompartmentSelectorIndex}
            onChange={(newSelected) => devicePoolStore.setState({ compartmentSelector: newSelected })}
            onError={() => this.setState({ activeStep: activeStep - 1 })}
          />
        </Grid>
      ),
    };
  };

  private templateStep = (): DynamicStepperStep => {
    const { devicePoolStore } = this.stores;
    const { coordinate, templateFilter } = devicePoolStore.state;

    const filterOptions = Object.values(TemplateFilterType).map((type: string) => (
      <MenuItem key={`template-filter-option-${type}`} value={type}>
        <ListItemText primary={type} />
      </MenuItem>
    ));

    return {
      title: 'Template Filter',
      elementCallback: () => (
        <Grid container item xs={8} spacing={2} alignContent={'stretch'}>
          <Grid item xs={12}>
            <StyledSelectField
              value={templateFilter.type}
              label={'Template Filter'}
              onChange={(e) => {
                devicePoolStore.setState({
                  templateFilter: {
                    type: e.target.value as TemplateFilterType,
                    templateNames: templateFilter.templateNames,
                  },
                });
              }}
            >
              {filterOptions}
            </StyledSelectField>
          </Grid>
          <Grid item xs={12}>
            <TemplatePicker
              multiple
              coordinate={coordinate}
              selected={templateFilter.templateNames}
              onChange={(selection: string | string[] | undefined) => {
                devicePoolStore.setState({
                  templateFilter: { type: templateFilter.type, templateNames: selection as string[] },
                });
              }}
            />
          </Grid>
        </Grid>
      ),
    };
  };

  private sortingStep = (): DynamicStepperStep => {
    const { devicePoolStore } = this.stores;
    const { sortingStrategy } = devicePoolStore.state;

    const sortingOptions = Object.values(SortingStrategyType).map((name: string) => (
      <MenuItem key={`sorting-strategy-option-${name}`} value={name}>
        <ListItemText primary={name} />
      </MenuItem>
    ));

    let metadataForm: JSX.Element;
    switch (sortingStrategy.name) {
      case SortingStrategyType.VALUE_ALPHANUMERIC:
        metadataForm = (
          <StyledTextField
            type={'text'}
            label={'Value'}
            value={sortingStrategy.metadata.value !== undefined ? (sortingStrategy.metadata.value as string) : ''}
            tooltip={'leave empty to sort by coordinate'}
            onChange={(e) => {
              devicePoolStore.setState({
                sortingStrategy: { name: sortingStrategy.name, metadata: { value: e.target.value as string } },
              });
            }}
          />
        );
        break;
      case SortingStrategyType.MANUAL:
        metadataForm = (
          <DevicePoolSorting
            devicePool={devicePoolStore.state}
            onChange={(compartments: CompartmentView[]) => {
              devicePoolStore.setState({
                sortingStrategy: {
                  name: sortingStrategy.name,
                  metadata: { coordinates: compartments.map((cv) => cv.coordinate) },
                },
              });
            }}
          />
        );
        break;
      default:
        metadataForm = <div></div>;
    }

    return {
      title: 'Sorting Strategy',
      elementCallback: () => (
        <Grid container item xs={8} spacing={2} alignContent={'stretch'}>
          <Grid item xs={12}>
            <StyledSelectField
              value={sortingStrategy.name}
              label={'Sorting Strategy'}
              onChange={(e) => {
                devicePoolStore.setState({
                  sortingStrategy: { name: e.target.value as SortingStrategyType, metadata: sortingStrategy.metadata },
                });
              }}
            >
              {sortingOptions}
            </StyledSelectField>
          </Grid>
          <Grid item xs={12}>
            {metadataForm}
          </Grid>
        </Grid>
      ),
    };
  };

  private overviewStep = (): DynamicStepperStep => {
    const { devicePoolStore } = this.stores;
    const { coordinate, name, compartmentSelector, templateFilter, sortingStrategy } = devicePoolStore.state;

    return {
      title: 'Overview',
      elementCallback: (): JSX.Element => (
        <Grid container item xs={10} spacing={2} alignItems={'stretch'}>
          <Grid container item xs={10}>
            <Grid item xs={12}>
              <Typography variant={'overline'} color={'secondary'}>
                Identifier
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3}>
              <Typography variant={'h6'} color={'secondary'}>
                Name
              </Typography>
              <Typography variant={'h5'}>{name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h6'} color={'secondary'}>
                Coordinate
              </Typography>
              <Typography variant={'h5'}>{coordinate}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={10}>
            <Grid item xs={12}>
              <Typography variant={'overline'} color={'secondary'}>
                Compartment Selector
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3}>
              <Typography variant={'h6'} color={'secondary'}>
                Name
              </Typography>
              <Typography variant={'h5'}>{compartmentSelector!.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h6'} color={'secondary'}>
                Coordinate
              </Typography>
              <Typography variant={'h5'}>{compartmentSelector!.coordinate}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={10}>
            <Grid item xs={12}>
              <Typography variant={'overline'} color={'secondary'}>
                Template Filter
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3}>
              <Typography variant={'h6'} color={'secondary'}>
                Type
              </Typography>
              <Typography variant={'h5'}>{templateFilter.type}</Typography>
            </Grid>
            {templateFilter.templateNames.length > 0 && (
              <Grid item xs={9}>
                <Typography variant={'h6'} color={'secondary'}>
                  Templates
                </Typography>
                <Typography variant={'h5'}>{templateFilter.templateNames.join(', ')}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid container item xs={10}>
            <Grid item xs={12}>
              <Typography variant={'overline'} color={'secondary'}>
                Sorting Strategy
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3}>
              <Typography variant={'h6'} color={'secondary'}>
                Name
              </Typography>
              <Typography variant={'h5'}>{sortingStrategy.name}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ),
    };
  };

  public render() {
    const { closeHandler, saveHandler } = this.props;
    const { activeStep } = this.state;
    const { devicePoolStore } = this.stores;
    const { name, coordinate, compartmentSelector } = devicePoolStore.state;

    const steps = [
      this.namingStep(),
      this.selectorStep(),
      this.templateStep(),
      this.sortingStep(),
      this.overviewStep(),
    ];

    const isNextDisabled =
      (activeStep === 0 && (name === '' || coordinate === '')) ||
      (activeStep === 1 && compartmentSelector === undefined) ||
      activeStep + 1 === steps.length;

    return (
      <Grid container spacing={2} alignContent={'stretch'}>
        <Grid item xs={12}>
          <DynamicStepper activeStep={activeStep} steps={steps} />
        </Grid>
        <Grid item xs={12}>
          <FormPanelButtons
            nextHandler={() => this.setState({ activeStep: activeStep + 1 })}
            backHandler={() => this.setState({ activeStep: activeStep - 1 })}
            saveHandler={() => {
              saveHandler();
              closeHandler();
            }}
            isNextDisabled={isNextDisabled}
            isBackDisabled={activeStep === 0}
            isSaveDisabled={!devicePoolStore.isSaveable}
            cancelHandler={closeHandler}
            isDeleteHidden={true}
          />
        </Grid>
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<DevicePoolPanelProps>(DevicePoolPanelComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const DevicePoolPanel = StyleWrapped;
