import { CompartmentSelectionStrategy, CompartmentSelector } from '@ekkogmbh/apisdk';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { noop } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { CompartmentSelectorStore } from '../Stores/CompartmentSelectorStore';

const styles = FormStyles;

interface CompartmentSelectorPanelStores {
  api: ApiStore;
  compartmentSelectorStore: CompartmentSelectorStore;
}

interface CompartmentSelectorPanelState {
  loading: boolean;
}

export interface CompartmentSelectorPanelProps extends InjectedNotistackProps, WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (compartmentSelector: CompartmentSelector) => Promise<CompartmentSelector>;
}

@inject('api', 'compartmentSelectorStore')
@observer
class CompartmentSelectorPanelComponent extends React.Component<
  CompartmentSelectorPanelProps,
  CompartmentSelectorPanelState
> {
  public state: CompartmentSelectorPanelState = {
    loading: false,
  };

  get stores(): CompartmentSelectorPanelStores {
    return this.props as CompartmentSelectorPanelProps & CompartmentSelectorPanelStores;
  }

  public componentWillUnmount(): void {
    const { compartmentSelectorStore } = this.stores;
    compartmentSelectorStore.resetStore();
  }

  public handleReset = async () => {
    const { compartmentSelectorStore } = this.stores;
    const { editableCompartmentSelector } = compartmentSelectorStore;

    if (editableCompartmentSelector !== undefined) {
      compartmentSelectorStore.setEditableCompartmentSelector(editableCompartmentSelector);
    } else {
      this.componentWillUnmount();
    }
  };

  public handleSave = async () => {
    const { saveHandler, closeHandler } = this.props;
    const { compartmentSelectorStore } = this.stores;

    const payload = compartmentSelectorStore.state as CompartmentSelector;

    this.setState({ loading: true }, async () => {
      await saveHandler(payload);
      this.handleReset();
      closeHandler();
    });
  };

  public renderConfigurationForm(): JSX.Element {
    const { compartmentSelectorStore } = this.stores;
    const { type, configuration, coordinate } = compartmentSelectorStore.state;

    switch (type) {
      case CompartmentSelectionStrategy.VALUE:
        return (
          <Grid item xs={12}>
            <StyledTextField
              type={'text'}
              label={'Compartment Field Key'}
              value={configuration.key ?? ''}
              onChange={(e) => compartmentSelectorStore.setState({ configuration: { key: e.target.value } })}
            />
          </Grid>
        );
      case CompartmentSelectionStrategy.COORDINATE:
        return (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <StyledTextField type={'text'} label={'Prefix'} value={coordinate} disabled={true} onChange={noop} />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                type={'text'}
                label={'Infix (optional)'}
                value={configuration.infix ?? ''}
                onChange={(e) =>
                  compartmentSelectorStore.setState({
                    configuration: e.target.value === '' ? {} : { infix: e.target.value },
                  })
                }
              />
            </Grid>
          </Grid>
        );
      case CompartmentSelectionStrategy.REGEX:
        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <StyledTextField
                type={'text'}
                label={'Pattern'}
                value={configuration.pattern ?? ''}
                onChange={(e) => compartmentSelectorStore.setConfigurationValue('pattern', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                type={'text'}
                label={'Replacement'}
                value={configuration.replacement ?? ''}
                onChange={(e) => compartmentSelectorStore.setConfigurationValue('replacement', e.target.value)}
              />
            </Grid>
          </Grid>
        );
      case CompartmentSelectionStrategy.VALUE_DELIMITER:
        return (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <StyledTextField
                type={'text'}
                label={'Key'}
                value={configuration.key ?? ''}
                onChange={(e) => compartmentSelectorStore.setConfigurationValue('key', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                type={'text'}
                label={'Delimiter'}
                value={configuration.delimiter ?? ''}
                onChange={(e) => compartmentSelectorStore.setConfigurationValue('delimiter', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                type={'number'}
                label={'Index'}
                value={configuration.index ?? 0}
                onChange={(e) => compartmentSelectorStore.setConfigurationValue('index', e.target.value)}
              />
            </Grid>
          </Grid>
        );
      case CompartmentSelectionStrategy.VALUE_MINMAX:
        return (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <StyledTextField
                type={'text'}
                label={'Key'}
                value={configuration.key ?? ''}
                onChange={(e) => compartmentSelectorStore.setConfigurationValue('key', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                type={'text'}
                label={'MinMax-Key'}
                value={configuration['minmax-key'] ?? ''}
                onChange={(e) => compartmentSelectorStore.setConfigurationValue('minmax-key', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" style={{ marginLeft: 8, width: '100%' }}>
                <InputLabel htmlFor="outlined-usemax">Min / Max</InputLabel>
                <Select
                  native
                  value={configuration['useMax']}
                  onChange={(e) => compartmentSelectorStore.setConfigurationValue('useMax', e.target.value as string)}
                  input={<OutlinedInput name="useMax" labelWidth={72} />}
                >
                  <option></option>
                  <option value="1">Max Value</option>
                  <option value="0">Min Value</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );
      default:
        return <Typography>select a type</Typography>;
    }
  }

  public renderValidateInputForm(): JSX.Element {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledTextField
            type={'text'}
            label={'Validation Regex'}
            value={configuration.inputValidation ?? ''}
            onChange={(e) => compartmentSelectorStore.setConfigurationValue('inputValidation', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            type={'text'}
            label={'Validation Error-Text'}
            value={configuration.inputValidationErrorText ?? ''}
            onChange={(e) => compartmentSelectorStore.setConfigurationValue('inputValidationErrorText', e.target.value)}
          />
        </Grid>
      </Grid>
    );
  }

  public render() {
    const { closeHandler, classes } = this.props;
    const { loading } = this.state;
    const { compartmentSelectorStore } = this.stores;
    const { editableCompartmentSelector } = compartmentSelectorStore;
    const { name, coordinate, configuration, type } = compartmentSelectorStore.state;

    const types = Object.entries(CompartmentSelectionStrategy);
    const typeOptions = types.map(([key, value]) => (
      <option key={`selector-strategy-${key}`}>{value.toString()}</option>
    ));

    const isDisabled = editableCompartmentSelector !== undefined;
    const withValidation = configuration.inputValidation !== undefined;

    return (
      <Grid container spacing={2} alignContent={'stretch'}>
        <Grid item xs={12}>
          <div style={{ display: loading ? 'block' : 'none' }}>
            <Grid container spacing={2} alignItems={'stretch'}>
              <Grid
                item
                xs={12}
                style={{
                  height: 496,
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    top: '50%',
                    marginTop: -48,
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  <CheckmarkSpinner complete={false} failure={false} />
                </div>
              </Grid>
            </Grid>
          </div>

          {!loading && (
            <Grid container item xs={6} spacing={2} alignContent={'stretch'}>
              <Grid item xs={12}>
                <StyledTextField
                  type={'text'}
                  label={'Name'}
                  value={name}
                  disabled={isDisabled}
                  onChange={(e) => compartmentSelectorStore.setState({ name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  type={'text'}
                  label={'Coordinate'}
                  value={coordinate}
                  disabled={isDisabled}
                  onChange={(e) => compartmentSelectorStore.setState({ coordinate: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.margin}>
                  <InputLabel htmlFor="outlined-selector-strategy">Type</InputLabel>
                  <Select
                    native
                    value={type}
                    onChange={(e) =>
                      compartmentSelectorStore.setState({
                        type: e.target.value as CompartmentSelectionStrategy,
                        configuration: {},
                      })
                    }
                    input={<OutlinedInput name="selectorType" labelWidth={36} id="outlined-selector-strategy" />}
                  >
                    {typeOptions}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {this.renderConfigurationForm()}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label={'Input-Validation'}
                  control={
                    <Checkbox
                      checked={withValidation}
                      onChange={() => {
                        if (withValidation) {
                          compartmentSelectorStore.removeConfigurationValue('inputValidation');
                          compartmentSelectorStore.removeConfigurationValue('inputValidationErrorText');
                        } else {
                          compartmentSelectorStore.setConfigurationValue('inputValidation', '');
                        }
                      }}
                    />
                  }
                  style={{ margin: 12 }}
                />
                {withValidation && this.renderValidateInputForm()}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormPanelButtons
            cancelHandler={closeHandler}
            saveHandler={this.handleSave}
            resetHandler={this.handleReset}
            isDeleteHidden={true}
          />
        </Grid>
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<CompartmentSelectorPanelProps>(CompartmentSelectorPanelComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const CompartmentSelectorPanel = StyleWrapped;
