import { CompartmentSelector, EslManagerPrivateRoute, HttpMethod } from '@ekkogmbh/apisdk';
import {
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  Tooltip,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ChangeEvent } from 'react';
import { Component } from 'react';
import { spacer } from 'src/Common/Components/Forms/Spacer';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { CompartmentGeneratorForm } from 'src/CompartmentManagement/Components/CompartmentGeneratorForm';
import { CompartmentGeneratorStore } from 'src/CompartmentManagement/Stores/CompartmentGeneratorStore';
import { LabelLinkStore } from 'src/LabelManagement/Stores/LabelLinkStore';
import { LinkProfileStore } from '../Stores/LinkProfileStore';

const styles = FormStyles;

interface LinkProfileConfigurationFormState {
  loading: boolean;
  compartmentSelectors: CompartmentSelector[];
}

interface LinkProfileConfigurationFormProps extends WithStyles<typeof styles>, InjectedNotistackProps {
  errorCallback: () => void;
}

interface LinkProfileConfigurationFormStores {
  api: ApiStore;
  linkProfileStore: LinkProfileStore;
  labelLinkStore: LabelLinkStore;
  compartmentGeneratorStore: CompartmentGeneratorStore;
}

type LinkProfileConfigurationFormPropsWithStores = LinkProfileConfigurationFormProps &
  LinkProfileConfigurationFormStores;

@inject('api', 'linkProfileStore', 'labelLinkStore', 'compartmentGeneratorStore')
@observer
class LinkProfileConfigurationFromComponent extends Component<
  LinkProfileConfigurationFormProps,
  LinkProfileConfigurationFormState
> {
  private fetchPromises: CancelableFetchPromises = {};

  public state: LinkProfileConfigurationFormState = {
    loading: true,
    compartmentSelectors: [],
  };

  get stores(): LinkProfileConfigurationFormPropsWithStores {
    return this.props as LinkProfileConfigurationFormPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    const { enqueueSnackbar, errorCallback } = this.props;
    const { linkProfileStore } = this.stores;
    const compartmentSelectors = await this.fetchCompartmentSelectors();

    if (compartmentSelectors.length < 1) {
      enqueueSnackbar('No Compartment Selectors found.');
      errorCallback();
      return;
    }

    this.setState({ compartmentSelectors, loading: false }, () =>
      linkProfileStore.setState({ compartmentSelector: compartmentSelectors[0] }),
    );
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public onCompartmentSelectorSelect = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { linkProfileStore } = this.stores;
    const { compartmentSelectors } = this.state;

    const value = event.target.value;
    const compartmentSelector = compartmentSelectors[value ? (value as number) : 0];

    linkProfileStore.setState({ compartmentSelector });
  };

  public onAutoTemplateGroupSelect = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { labelLinkStore, linkProfileStore } = this.stores;
    const groupNames = labelLinkStore.getGroupNames();

    const value = event.target.value as number;
    const autoTemplateGroup = value >= 0 ? groupNames[value] : undefined;

    linkProfileStore.setState({ autoTemplateGroup });
  };

  public async fetchCompartmentSelectors(): Promise<CompartmentSelector[]> {
    const { enqueueSnackbar } = this.props;
    const { api, linkProfileStore } = this.stores;
    const { coordinate } = linkProfileStore.state;

    const compartmentSelectors = request<CompartmentSelector[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getAvailableCompartmentSelectors(coordinate),
      EslManagerPrivateRoute.AVAILABLE_COMPARTMENT_SELECTORS,
      HttpMethod.GET,
    );

    return compartmentSelectors;
  }

  public getCompartmentSelectorIndex = (selector?: CompartmentSelector) => {
    const { compartmentSelectors } = this.state;

    if (selector === undefined) {
      return -1;
    }

    return compartmentSelectors.findIndex(
      (cs: CompartmentSelector) => cs.name === selector.name && cs.coordinate === selector.coordinate,
    );
  };

  public render(): JSX.Element {
    const { compartmentSelectors } = this.state;
    const { linkProfileStore, labelLinkStore, compartmentGeneratorStore } = this.stores;
    const { autoTemplateGroup, compartmentSelector, removeObsoleteCompartments } = linkProfileStore.state;

    const templateGroups = labelLinkStore.getGroupNames();
    const templateGroupOptions = templateGroups.map((groupName: string, index: number) => {
      const groupKey = `auto-template-option-${index}`;
      return (
        <MenuItem key={groupKey} value={index}>
          <ListItemText primary={groupName} />
        </MenuItem>
      );
    });

    const selectorOptions = compartmentSelectors.map((selector: CompartmentSelector, index: number) => {
      const selectorKey = `${selector.name}@${selector.coordinate}`;
      return (
        <MenuItem key={selectorKey} value={index}>
          <ListItemText primary={selectorKey} />
        </MenuItem>
      );
    });

    return (
      <Fade in={true} timeout={1000}>
        <Grid container alignItems={'stretch'}>
          <Grid container item xs={12} spacing={1} alignItems={'stretch'} alignContent={'stretch'}>
            <Grid item xs={6}>
              <StyledSelectField
                label={'Automatic Template Group'}
                value={templateGroups.indexOf(autoTemplateGroup ?? '-')}
                onChange={this.onAutoTemplateGroupSelect}
              >
                <MenuItem value={-1}>
                  <ListItemText primary={'-'} />
                </MenuItem>
                {templateGroupOptions}
              </StyledSelectField>
            </Grid>
            {spacer(6)}
            {compartmentSelector && (
              <Grid item xs={6}>
                <StyledSelectField
                  label={'Compartment Selector'}
                  value={this.getCompartmentSelectorIndex(compartmentSelector)}
                  onChange={this.onCompartmentSelectorSelect}
                >
                  {selectorOptions}
                </StyledSelectField>
              </Grid>
            )}
            {spacer(6)}
            <Grid item xs={6}>
              <StyledTextField
                type={'text'}
                label={'Label Input Instructions'}
                tooltip={'Instruction text displayed when applying the profile.'}
                value={linkProfileStore.state.labelInputInstructions ?? ''}
                onChange={(e) => linkProfileStore.setState({ labelInputInstructions: e.target.value })}
              />
            </Grid>
            {spacer(6)}
            <Grid item xs={6}>
              <StyledTextField
                type={'text'}
                label={'Compartment Input Instructions'}
                tooltip={'Instruction text displayed when applying the profile.'}
                value={linkProfileStore.state.compartmentInputInstructions ?? ''}
                onChange={(e) => linkProfileStore.setState({ compartmentInputInstructions: e.target.value })}
              />
            </Grid>
            {spacer(6)}
            <Grid item xs={6}>
              <Tooltip title={'Remove compartments left without linked labels'}>
                <FormControlLabel
                  label={'Remove Obsolete Compartments'}
                  control={
                    <Checkbox
                      checked={removeObsoleteCompartments}
                      onChange={() =>
                        linkProfileStore.setState({ removeObsoleteCompartments: !removeObsoleteCompartments }, false)
                      }
                    />
                  }
                  style={{ margin: 12 }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={'Create a compartment if the selector can not find one'}>
                <FormControlLabel
                  label={'Compartment Creation'}
                  control={
                    <Checkbox
                      checked={compartmentGeneratorStore.isEnabled}
                      onChange={() => compartmentGeneratorStore.toggleEnabled()}
                    />
                  }
                  style={{ margin: 12 }}
                />
              </Tooltip>
            </Grid>
          </Grid>
          {compartmentGeneratorStore.isEnabled && (
            <Grid item xs={11}>
              <CompartmentGeneratorForm />
            </Grid>
          )}
        </Grid>
      </Fade>
    );
  }
}

const SnackbarWrapped = withSnackbar<LinkProfileConfigurationFormProps>(LinkProfileConfigurationFromComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const LinkProfileConfigurationForm = StyleWrapped;
