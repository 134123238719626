import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from 'src/Common/Components/Page';
import { TitleProps } from 'src/Routes';
import { FinderManagementContent } from './Components/FinderManagementContet';
import { FinderStore } from './Stores/FinderStore';

const finderStore = new FinderStore();

const stores = { finderStore };

export const FinderManagement = ({ title }: TitleProps): JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <FinderManagementContent />
    </Page>
  </Provider>
);
