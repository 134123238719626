import { LoggedInUser } from '@ekkogmbh/apisdk';
import { Button, Grid, Paper, Typography, withStyles, WithStyles } from '@material-ui/core';
import { KeyboardArrowLeft, Mail } from '@material-ui/icons';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  CancelableFetchPromises,
  cancelFetchPromises,
  makePromiseCancelable,
  noop,
} from '../../Common/Helper/PromiseHelper';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { YourProfileStyles } from '../Styles/YourProfileStyles';
import { UserRoleCard } from './UserRoleCard';
import { ChangePasswordForm } from './ChangePasswordForm';
import { ConfigStore } from 'src/Common/Stores/ConfigStore';

interface YourProfileContentStores {
  api: ApiStore;
  configStore: ConfigStore;
}

const stores = ['api', 'configStore'];

interface YourProfileContentProps
  extends WithStyles<typeof YourProfileStyles>,
    RouteComponentProps,
    InjectedNotistackProps {}

@inject(...stores)
@observer
class YourProfileContentComponent extends Component<YourProfileContentProps> {
  public state: {
    changePasswordView: boolean;
    loading: boolean;
    user?: LoggedInUser;
  } = {
    changePasswordView: false,
    loading: false,
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): YourProfileContentStores {
    return this.props as YourProfileContentProps & YourProfileContentStores;
  }

  public componentDidMount(): void {
    const fetchData = async () => {
      await this.fetchProfile();
      this.setState({ loading: false });
    };

    this.setState({ loading: true }, fetchData);
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchProfile = async (): Promise<void> => {
    const { api } = this.stores;

    this.fetchPromises['currentUser'] = makePromiseCancelable(api!.fetchCurrentUser());

    const user = await this.fetchPromises['currentUser'].promise.catch(noop);

    this.setState({ user });
  };

  public toggleView = (): void => {
    const { changePasswordView } = this.state;
    this.setState({ changePasswordView: !changePasswordView });
  };

  public renderProfile = (): JSX.Element => {
    const { classes } = this.props;
    const { configStore } = this.stores;
    const { user } = this.state;

    const useJwtAuth = configStore.config.api.authMechanism === 'jwt';

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classNames(classes.root)}>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <Typography className={classNames(classes.iconText)}>
                  Welcome, {user!.username} (<Mail /> {user!.email}).
                </Typography>
                <Typography>Your account expires {moment(user!.validUntil).fromNow()}.</Typography>
              </Grid>
              {useJwtAuth && (
                <Grid item>
                  <Button variant={'outlined'} color={'secondary'} onClick={this.toggleView}>
                    <Typography>Change Password</Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classNames(classes.root)}>
            <Typography className={classNames(classes.title)}>Roles</Typography>
            <Grid container spacing={2}>
              {user!.roleMappings &&
                user!.roleMappings.map((mapping, index) => (
                  <Grid item xs={3} key={index}>
                    <UserRoleCard nodeRoleMapping={mapping} />
                  </Grid>
                ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  public render() {
    const { classes } = this.props;
    const { loading, user, changePasswordView } = this.state;

    return (
      <Grid item xs={12}>
        {loading && <LoadingMask width={75} height={75} />}
        {user && !changePasswordView && this.renderProfile()}
        {changePasswordView && (
          <Paper className={classNames(classes.root, classes.dataTablePaper)}>
            <Button
              size={'small'}
              style={{ borderRadius: 0 }}
              variant="text"
              color="secondary"
              onClick={this.toggleView}
            >
              <KeyboardArrowLeft /> GO Back
            </Button>
            <ChangePasswordForm />
          </Paper>
        )}
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<YourProfileContentProps, typeof YourProfileContentComponent>(
  YourProfileContentComponent,
);
const SnackbarWrapped = withSnackbar<Omit<YourProfileContentProps, keyof RouteComponentProps>>(RouterWrapped);
const StyleWrapped = withStyles(YourProfileStyles)(SnackbarWrapped);

export const YourProfileContent = StyleWrapped;
