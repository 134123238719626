import {
  ApplicableRenderersPayload,
  ApplicableRenderersResult,
  IsTemplateApplicable,
  RendererPayload,
  RendererResult,
  TemplateType,
} from '@ekkogmbh/apisdk';
import { withStyles, WithStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import SaveAlt from '@material-ui/icons/SaveAlt';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Dropzone from 'react-dropzone';
import { ConfigStore } from 'src/Common/Stores/ConfigStore';
import { CSVDropzoneStyles } from '../../Common/Styles/CSVDropzoneStyles';
import { TemplateStore } from '../Stores/TemplateStore';

const styles = CSVDropzoneStyles;

interface TemplateDropzoneStores {
  templateStore: TemplateStore;
  configStore: ConfigStore;
}

interface TemplateDropzoneProps extends WithStyles<typeof styles> {
  fetchRendererResult: (payload: RendererPayload) => Promise<RendererResult>;
  getApplicableRenderers: (payload: ApplicableRenderersPayload) => Promise<ApplicableRenderersResult>;
}

@inject('templateStore', 'configStore')
@observer
class TemplateDropzoneComponent extends React.Component<TemplateDropzoneProps> {
  get stores(): TemplateDropzoneStores {
    return this.props as TemplateDropzoneProps & TemplateDropzoneStores;
  }

  public onDrop = (files: File[]) => {
    if (files.length === 0) {
      throw new Error(`Panic: OnDrop called while no files were provided`);
    }

    const { templateStore } = this.stores;
    const file = files[0];
    const reader = new FileReader();

    templateStore.setFile(file);
    reader.onload = () => this.onSuccess(reader.result as string);

    reader.readAsDataURL(file);
  };

  public onSuccess = async (result: string) => {
    const { configStore, templateStore } = this.stores;
    const { fetchRendererResult, getApplicableRenderers } = this.props;
    const { coordinateFieldName, labelIdFieldName } = configStore.config;
    // Prepare base64 string, by removing the data url header (data:type,) as
    // well as ensuring the base64 string is properly padded.
    // Note: The comma (,) is not part of the base64 alphabet, therefore we can
    // remove everything up to its occurrence.
    let base64Data = result.replace(/^.*,/, '');
    // Padding: The string should olways be a multiple of 4
    const danglingCharacterCount = base64Data.length % 4;
    if (danglingCharacterCount % 4 !== 0) {
      base64Data = base64Data + '='.repeat(4 - danglingCharacterCount);
    }

    templateStore.setState({
      data: base64Data,
    });

    const applicableRenderers = await getApplicableRenderers({ data: templateStore.state.data });
    let applicableRendererType: TemplateType | undefined = undefined;
    for (const key of Object.keys(applicableRenderers) as TemplateType[]) {
      if ((key as unknown) === 'identity') {
        // The "identity" renderer is only used for testing purposes, but could
        // be active within our development deployments. To make life easier we
        // are simply skipping it here.
        continue;
      }
      const result: IsTemplateApplicable = applicableRenderers[key];
      if (result.isApplicable) {
        applicableRendererType = key;
        break;
      }
    }

    if (applicableRendererType === undefined) {
      // None of the renders was applicable. Let's output the reasons for it.
      const errors = Object.keys(applicableRenderers)
        .filter((candidate) => candidate !== 'identity')
        .map((key) => {
          const result = applicableRenderers[key];
          return `Renderer of type ${key} is not applicable to render the given template: ${result.reason}`;
        });

      templateStore.setRenderResult({
        image: '',
        fields: {},
        errors,
      });
      return;
    }

    // We have identified an applicable renderer
    templateStore.setState({ type: applicableRendererType });

    const payload = {
      data: templateStore.state.data,
      type: templateStore.state.type,
      fields: {},
      autoFill: true,
      render: true,
    };

    const rendering = await fetchRendererResult(payload);

    const keys = Object.keys(rendering.fields);

    if (keys.includes(coordinateFieldName)) {
      const newFields = rendering.fields;
      delete newFields[coordinateFieldName];
      rendering.fields = newFields;
      templateStore.reservedFieldCoordinate = true;
    } else {
      templateStore.reservedFieldCoordinate = false;
    }

    if (keys.includes(labelIdFieldName)) {
      const newFields = rendering.fields;
      delete newFields[labelIdFieldName];
      rendering.fields = newFields;
      templateStore.reservedFieldLabelId = true;
    } else {
      templateStore.reservedFieldLabelId = false;
    }

    templateStore.setRenderResult(rendering);
  };

  public onCancel = () => {
    const { templateStore } = this.stores;
    templateStore.removeFile();
  };

  public render() {
    const { classes } = this.props;
    const acceptedTemplateFileTypes = this.stores.configStore.config.acceptedTemplateFileTypes;
    const acceptProperty = acceptedTemplateFileTypes.join(',');

    return (
      <section style={{ width: '100%' }}>
        <Dropzone accept={acceptProperty} onDrop={this.onDrop} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classNames(classes.dropZone)}>
              <input {...getInputProps()} />
              <div>
                <SaveAlt className={classNames(classes.uploadIcon)} />

                <Typography className={classNames(classes.text)}>
                  Drop a layout file here, or click to select a layout file
                </Typography>
              </div>
            </div>
          )}
        </Dropzone>
      </section>
    );
  }
}

export const TemplateDropzone = withStyles(styles)(TemplateDropzoneComponent);
