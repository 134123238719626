import { LinkProfile, SaveCompartmentSelectorPayload } from '@ekkogmbh/apisdk';
import { Chip, Typography } from '@material-ui/core';
import { Check, Clear, Delete, Edit } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import React from 'react';
import { DatatableColumnDefinitionFn, TableMeta } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  LinkProfileManagementContentActionHandlers,
  LinkProfileManagementContentPropsWithStores,
  LinkProfileManagementContentState,
} from './LinkProfileManagementContent';

class LinkProfileDataTableActions extends DataTableActionsComponent<LinkProfile> {}

const LinkProfileActions = (
  _: LinkProfileManagementContentState,
  __: LinkProfileManagementContentPropsWithStores,
  actions: LinkProfileManagementContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    width: 48 * 2,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (linkProfile: LinkProfile, tableMeta: TableMeta): React.ReactNode => {
      const actionButtons: ActionButton<LinkProfile>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return (
        <LinkProfileDataTableActions
          dataset={linkProfile}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

const Name = (): MaterialDatatableColumnDef => ({
  name: 'Name',
  field: 'name',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: LinkProfile): React.ReactNode => <div style={{ fontWeight: 700 }}>{value.name}</div>,
  },
});

const Coordinate = (): MaterialDatatableColumnDef => ({
  name: 'Coordinate',
  field: 'coordinate',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: LinkProfile): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{value.coordinate}</div>
    ),
  },
});

const Technology = (): MaterialDatatableColumnDef => ({
  name: 'Technology',
  field: 'technology',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
  },
});

// eslint-disable-next-line react/display-name
const TemplateGroupsCustomBody = (value: LinkProfile, tableMeta: TableMeta) => {
  if (!value[tableMeta.columnData.field]) {
    return;
  }

  const templateGroups = Object.keys(value[tableMeta.columnData.field]);

  return (
    <div>
      {templateGroups.map((name: string) => {
        const isAutoGroup = value['autoTemplateGroup'] === name;
        return (
          <Chip
            style={{ margin: 8 }}
            key={`group-field-${name}`}
            variant={isAutoGroup ? 'outlined' : 'default'}
            label={
              <Typography variant={'subtitle2'} color={'textPrimary'}>
                {name}
              </Typography>
            }
          />
        );
      })}
    </div>
  );
};

const TemplateGroups = (
  _: LinkProfileManagementContentState,
  __: LinkProfileManagementContentPropsWithStores,
): MaterialDatatableColumnDef => ({
  name: 'Template Groups',
  field: 'templateGroups',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: TemplateGroupsCustomBody,
  },
});

const CompartmentSelectorCustomBody = (value: LinkProfile, tableMeta: TableMeta) => {
  if (!value[tableMeta.columnData.field]) {
    return;
  }

  // @FIXME: this is the save type because LinkProfile does not yet use the CompartmentSelectorEntity
  const compartmentSelector: SaveCompartmentSelectorPayload = value[tableMeta.columnData.field];
  const parameter = Object.values(compartmentSelector.configuration)[0];

  return (
    <Typography>
      {compartmentSelector.type} [ {parameter} ]
    </Typography>
  );
};

const CompartmentSelectorColumn = (): MaterialDatatableColumnDef => ({
  name: 'Compartment Selector',
  field: 'compartmentSelector',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: CompartmentSelectorCustomBody,
  },
});

const LabelInstructionsColumn = (): MaterialDatatableColumnDef => ({
  name: 'Label Input Instructions',
  field: 'noMatchStrategy',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: LinkProfile): React.ReactNode => (
      <Typography>{value.labelInputInstructions ?? '-'}</Typography>
    ),
  },
});

const CompartmentInstructionsColumn = (): MaterialDatatableColumnDef => ({
  name: 'Compartment Input Instructions',
  field: 'compartmentInputInstructions',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: LinkProfile): React.ReactNode => (
      <Typography>{value.compartmentInputInstructions ?? '-'}</Typography>
    ),
  },
});

const CompartmentCreationColumn = (): MaterialDatatableColumnDef => ({
  name: 'Compartment Creation',
  field: 'noMatchStrategy',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: LinkProfile): React.ReactNode =>
      value.noMatchStrategy && value.noMatchStrategy.name !== 'null' ? <Check /> : <Clear />,
  },
});

const RemoveObsoleteCompartmentsColumn = (): MaterialDatatableColumnDef => ({
  name: 'Compartment Removal',
  field: 'removeObsoleteCompartments',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: LinkProfile): React.ReactNode =>
      !!value.removeObsoleteCompartments ? <Check /> : <Clear />,
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  LinkProfileManagementContentPropsWithStores,
  LinkProfileManagementContentState,
  LinkProfileManagementContentActionHandlers
>> = [
  Name,
  Coordinate,
  Technology,
  TemplateGroups,
  CompartmentSelectorColumn,
  LabelInstructionsColumn,
  CompartmentInstructionsColumn,
  CompartmentCreationColumn,
  RemoveObsoleteCompartmentsColumn,
  LinkProfileActions,
];
