import { CompartmentSelector, LinkProfile, TemplateGroups } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export interface LinkProfileState {
  name: string;
  technology: string;
  coordinate: string;
  compartmentSelector?: CompartmentSelector;
  templateGroups: TemplateGroups;
  autoTemplateGroup?: string;
  removeObsoleteCompartments: boolean;
  labelInputInstructions?: string | null;
  compartmentInputInstructions?: string | null;
  allFilled: boolean;
}

export class LinkProfileStore {
  @observable
  public editableLinkProfile?: LinkProfile;

  @observable
  public state: LinkProfileState = {
    name: '',
    technology: '',
    coordinate: '',
    compartmentSelector: undefined,
    templateGroups: {},
    autoTemplateGroup: undefined,
    removeObsoleteCompartments: false,
    allFilled: false,
  };

  @action
  public setState = (newState: Partial<LinkProfileState>, setAllFilled: boolean = true): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    if (setAllFilled) {
      const allFilled =
        this.state.name !== '' &&
        this.state.technology !== '' &&
        this.state.coordinate !== '' &&
        this.state.compartmentSelector !== undefined &&
        this.state.templateGroups !== undefined;

      this.state = {
        ...this.state,
        allFilled,
      };
    }
  };

  @action
  public resetStore = (): void => {
    this.setState({
      name: '',
      technology: '',
      coordinate: '',
      compartmentSelector: undefined,
      templateGroups: undefined,
      autoTemplateGroup: '',
      removeObsoleteCompartments: false,
      labelInputInstructions: undefined,
      compartmentInputInstructions: undefined,
    });

    this.editableLinkProfile = undefined;
  };

  @action
  public setEditableLinkProfile = (linkProfile?: LinkProfile): void => {
    this.editableLinkProfile = linkProfile;

    if (linkProfile) {
      const {
        name,
        technology,
        coordinate,
        autoTemplateGroup,
        removeObsoleteCompartments,
        labelInputInstructions,
        compartmentInputInstructions,
      } = linkProfile;

      this.setState({
        name,
        technology,
        coordinate,
        labelInputInstructions,
        compartmentInputInstructions,
        compartmentSelector: undefined,
        autoTemplateGroup: autoTemplateGroup ?? undefined,
        removeObsoleteCompartments: !!removeObsoleteCompartments,
      });
    }
  };
}
