import { CompartmentSelectionStrategy, CompartmentSelector, CompartmentSelectorConfiguration } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export interface CompartmentSelectorState {
  name: string;
  coordinate: string;
  type: CompartmentSelectionStrategy;
  configuration: CompartmentSelectorConfiguration;
  allFilled: boolean;
}

export class CompartmentSelectorStore {
  @observable
  public editableCompartmentSelector?: CompartmentSelector;

  @observable
  public state: CompartmentSelectorState = {
    name: '',
    coordinate: '',
    type: CompartmentSelectionStrategy.REGEX,
    configuration: {},
    allFilled: false,
  };

  @action
  public setState = (newState: Partial<CompartmentSelectorState>, setAllFilled: boolean = true): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    if (setAllFilled) {
      const allFilled = this.state.name !== '' && this.state.coordinate !== '' && this.state.type !== undefined;
      this.state = {
        ...this.state,
        allFilled,
      };
    }
  };

  @action
  public resetStore = (): void => {
    this.setState({
      name: '',
      coordinate: '',
      type: CompartmentSelectionStrategy.REGEX,
      configuration: {},
      allFilled: false,
    });

    this.editableCompartmentSelector = undefined;
  };

  @action
  public setEditableCompartmentSelector = (compartmentSelector: CompartmentSelector): void => {
    this.editableCompartmentSelector = compartmentSelector;

    if (compartmentSelector) {
      this.setState(compartmentSelector as CompartmentSelectorState, true);
    }
  };

  @action
  public setConfigurationValue = (key: string, value: string): void => {
    console.log(key);
    console.log(value);
    const newConfig = {
      ...this.state.configuration,
      [key]: value,
    };

    this.setState({ configuration: newConfig });
  };

  public removeConfigurationValue = (key: string): void => {
    const newConfig = {
      ...this.state.configuration,
    };

    delete newConfig[key];

    this.setState({ configuration: newConfig });
  };
}
