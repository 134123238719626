import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from 'src/Common/Components/Page';
import { TitleProps } from 'src/Routes';
import { CompartmentSelectorManagementContent } from './Components/CompartmentSelectorManagementContent';
import { CompartmentSelectorStore } from './Stores/CompartmentSelectorStore';

const compartmentSelectorStore = new CompartmentSelectorStore();

const stores = {
  compartmentSelectorStore,
};

export const CompartmentSelectorManagement = ({ title }: TitleProps): JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <CompartmentSelectorManagementContent />
    </Page>
  </Provider>
);
